.adventures-gallery {
   .filter-section {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .field-radio-label {
      font-size: 1110px;
    }
    .field-radio-input {
      width: 20px;
      height: 20px;
      cursor:pointer
    }
    .filter-free-email {
      display: flex;
      align-items: center;
      button {
        margin-right: 15px;
      }
    }
  }



  .table-items-header-field {
    width: 100%;
    padding-left: 10px;
  }
  .table-items-header-button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: none;
    margin-top: 10px;
    text-decoration: underline;
    color:royalblue;
  }
}
