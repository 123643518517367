.adventure-type-sequence {
  position: relative;
  padding-top: 0;
  &.frameActive {
    min-height: 600px;
    margin-bottom: 50px;
  }
  .add-riddle-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    input {
      background-color: transparent;
    }
  }
  .add-riddle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 12px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #5e5e5e;
    border:0;
    cursor: pointer;
    &.no-riddles {
      width: auto;
      border-radius: 5px;
      color:#fff;
    }
  }
  .add-riddle-input {
    border:0;
    border-bottom: 2px solid #000;
    font-size: 16px;
    font-weight: 800;
    outline: 0;
    &:focus {
      border-bottom: 2px solid #5388ff;
    }
  }
  ul {
    padding-right: 0;
  }
  .scene-frames-container {

  }
}
.sidebar-item-icon-style{
  text-decoration: none;
  
}
.riddle-drag-handle {
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.single-riddle-modal {
  position: absolute;
  top: 84px;
  left:0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transition: transform .2s;
  z-index: 10000000;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  transform: translateY(calc(100% - 80px));
  &.active {
    transform: translateY(0);
  }
}

.single-riddle-modal-content {
  width: 100%;
}
.scene-wrapper {
  margin-bottom: 10px;
}
.scene-error {
  position: fixed;
  bottom: 0;
  width: 100%;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
  background-color: #8a1616;
  padding: 10px 0;
  transform: translateY(100px);
  transition: transform .3s, opacity .2s;
  opacity: 0;
  &.active {
    transform: translateY(0);
    opacity: 1;
  }
  p {
    font-size: 22px;
    color: #ffffff;
    margin: 0;
  }
}

.scene-row {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  //padding:  15px 10px 0;
  //margin-bottom: 15px;
  border-radius: 5px;
}

.scene-name {
  border-bottom: 1px solid #000;
  display: flex;
  align-items: center;
  font-weight: bold;
}
.scene-icon-name {
  padding-right: 20px;
  input {
    border:0;
    padding-right: 10px;
    border-bottom: 2px solid #fff;
    &:hover, &:focus {
      border-bottom: 2px solid #00a2ff;
    }
  }

}

.scene-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .images-trigger span {
    padding-right: 0;
    cursor: pointer;
  }
}

.scene-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
  p {
    padding-right: 5px;
    font-size: 14px;
  }
}

.scene-actions-add {
  margin: 0 10px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
}

.scene-icon {
  margin: 0 10px;
  flex:0;
  width:0;
  height: 45px;
  background-size: cover;
  background-position: center center;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  display: flex;
  @media (min-width:640px) {
    flex: auto;
    width: auto;
  }
  .images-container {
    align-items: center;
  }
}

.scene-frames-container {
  display: none;
  &.open {
    display: block;
  }
}
#app-sidebar{
  overflow: scroll;
}
#app-sidebar:hover + .route-section .adventure-type-single-frame,
#app-sidebar.open + .route-section .adventure-type-single-frame{
    width: calc(100% - 232px);
}

.adventure-type-single-frame {
  position: fixed;
  left:0;
  top: 77px;
  width: calc(100% - 55px);
  height: calc(100vh - 77px);
  transition:  width .3s;
  z-index: 10;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: auto;
  transform: translateY(-100%);
  .sidebar:hover  & {}
  .app-routes & {
    .sidebar:hover {

    }

  }
  &.active {
    transform: translateY(0);

  }
  &.fixed {
    position: fixed;
  }
}