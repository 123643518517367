.tool-slider {
  margin: 0 auto;
}
.tool-slider-content {
  text-align: right;
  padding: 5px 10px;
  h5 {
    font-size: 1.8rem;
    margin: 0;
  }
  p {
    font-size: 1.4rem;
    margin-bottom: 0;
  }
}