.tool-password {
  text-align: center;
}

.tool-password-input {
  text-align: center;
  padding: 10px 0 20px;

  input {
    height: 40px;
    width: 200px;
    border-radius: 10px;
    color: #fff;
    background-color: #6c757d;
    border: 2px solid #fff;
    outline: none;
    padding: 0 10px;
    font-size: 20px;
    text-align: center;

    &::placeholder {
      color: #fff
    }
  }
}

.tool-password-label {
  font-size: 20px;
  text-align: center;
  margin-bottom: 10px;
}

.tool-password-message {
  font-size: 14px;
  text-align: center;
}

.tool-password-continue {
  display: block;
  margin: 20px auto 0;
  padding: 10px 30px;
  font-size: 20px;
  border-radius: 20px;
  background-color: #08a48f;
  border: 0;
  color: #fff;
  cursor: pointer;
  outline: 0;
}