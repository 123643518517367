@import "./variables/variables-gutters.scss";
@import "./variables/variables-colors.scss";
@import "./variables/variables-font-size.scss";
@import "./variables/keyframes.scss";
@import "./variables/variables-viewports.scss";


.screen-user-container {
  max-width: 1200px;
  margin: 0 auto;
  //padding-top: 30px;
  width: 100%;
  overflow: visible;

  h1 {
    margin-top:0;
    font-size: $font-size-xxl;
    @media (max-width: $viewport-md) {
      font-size: $font-size-lg;
    }
  }
}

.screen-user-item {
  display: flex;
  width: 100%;
  padding: 10px 0;
  overflow: visible;
  //height: 120px;
  &.minimized{
    height: 50px;
    padding: 0;
  }
  margin: 30px 0;
  //box-shadow: 0 0 2.2px rgba(0, 0, 0, 0.3);
  position: relative;
  transition: .2s transform;
  background-color: #fff;
  transform: scale(.99);
  .theme-dark & {
    background-color: #000;
  }
  .menu {
    cursor: pointer;
    z-index: 10;
  }
  //&:hover {
  //  transform: scale(.96);
  //}

  &.minimized {
    .screen-user-item-details {
      padding: 0;
    }
  }
  .screen-user-item-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10px 5px;


    @media (min-width: $viewport-xs) {
      padding: 10px 30px;
      flex-direction: row;
    }
    .screen-user-item-details-header {
      display: flex;
      justify-content: space-between;
      //padding: 10px 20px;
      p {
        margin-top: 0
      }
    }

    .screen-user-item-details-footer {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      align-items: center;
      padding: 10px 0;
      @media (min-width: $viewport-md) {
        flex-direction: column;
        justify-content: space-around;
      }
      .screen-user-item-edit, .screen-user-item-delete{
        cursor: pointer;
        color: $color-white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        width: 90px;
        height: 32px;
        font-size: medium;
        background-color: $color-green;
        margin-left: 10px;
        @media (min-width: $viewport-md) {
          margin-left: 0;
        }

      }
      .screen-user-item-delete{
        background-color: $color-pink;
      }
    }
  }
  }

.adventures-proceed-button{
  border: 3px solid black;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  color: black;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: medium;
  font-weight: bold;
  margin: 0 10px;
  cursor: pointer;
  user-select: none;
  &:hover{
    filter: brightness(80%);
  }
  &.disabled{
    opacity: .5;
    cursor: context-menu;
    &:hover{
      filter: brightness(100%);
    }
  }

}
.screen-user-item-image {
  display: none;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  .minimized & {
    display: flex;
  }
  @media (min-width: $viewport-xs) {
    display: flex;
  }
}
.screen-user-item-details-titles {
  flex: 1;
}
.screen-user-item-title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 0;
  margin-top: 0;
  .theme-dark & {
    color: $color-white;
  }
  input {
    font-weight: bold;
    font-size: 22px;
    border: 0 !important;
    padding-right: 0;
    height: auto;
    &:hover {
      border: 0;
    }
    .theme-dark & {
      color: $color-white;
    }
  }
}



.screen-user-item-toggle-menu {
  cursor: pointer;
}

.screen-user-item-created {
  font-size: 13px;
}



.screen-user-item-actions {
  display: flex;

  button {
    margin: 0 10px;
    font-weight: bold;
    padding: 0;
    width: 80px;
    height: 40px;
border: 0;
    border-radius: 5px;

    a {
      color: #fff;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:last-child {
      margin-left: 0;
    }
  }
}

.screen-user-item-play {
 }

.screen-user-item-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;

  p {
    margin: 0
  }
}

.screen-user-item-passcode {
  font-size: 12px;
  font-weight: bold;
  color: #000;
}

.screen-user-gallery{
  height: 100%;
  overflow: visible;
}