.instructions {

}
.instructions-wrapper {
  background-color: #ffffff;
  padding: 15px 0;

}
.instructions-header {
  background-color: #efeeee;
  padding: 15px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.instructions-title {
  font-size: 18px;
  margin: 10px 0;
  display: block;
}
.instructions-body {
}