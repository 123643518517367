.rotary-wrapper {
  overflow: visible;
  margin: 25px 25px;
  display: flex;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  position: relative;

  &.inside {
    margin: 10px;
  }

  .rotary {
    height: 90%;
    width: 90%;
    margin: 5%;
    border-radius: 50%;
    border-style: solid;
    //background-color: transparent;
    display: flex;
    transition-duration: 0.4s;

    .rotary-line {

      position: absolute;
      z-index: 30;
      margin: auto;
    }

    .rotary-dot {
      position: absolute;
      z-index: 30;
      margin: auto;
    }

    .rotary-arrow {
      position: absolute;
      z-index: 30;
      margin: auto;
      right: calc(50% - 10px);
      //top: 25%;
      width: 20px;
      text-align: center;

      &.out {
        top: 0;
      }

      //&::after{
      //  content: " ";
      //  display: block;
      //  width: 30px;
      //  height: 30px;
      //  position: relative;
      //  right: calc(50% - 22.5px);
      //  top: -10px;
      //  transform: rotate(45deg);
      //  border-width: 15px 0  0 15px ;
      //  border-style: solid;
      //}
    }
  }
  .rotary-label {
    position: absolute;
    width: 100%;
    bottom: -30px;
    margin: 0;
    text-align: center;
  }
  .rotary-numbers {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;

    &.inside {
      height: 100%;
      width: 100%;
    }

    .rotary-number {
      position: absolute;
      height: 100%;
      display: flex;
      align-items: flex-start;
      margin-bottom: 800px;
    }
  }
}