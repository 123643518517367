
.app-wrapper {
  margin: 0 auto;
  transition: padding-right .3s;

  * {
    direction: ltr;
  }

  .lang-rtl &, .route-admin {
    direction: rtl;
    * {
      direction: rtl;
    }
  }
}

