@import "./variables/variables-gutters.scss";
@import "./variables/variables-colors.scss";
@import "./variables/variables-font-size.scss";
@import "./variables/keyframes.scss";
@import "./variables/variables-viewports.scss";

.adventure-riddle {
  background-color: $color-white;
  position: relative;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border: 2px solid $color-black;
  overflow: hidden;
  .theme-dark & {
    border: 2px solid $color-white;
    background-color: $color-black;
  }
  &.active {
    border-bottom-right-radius: 0;
  }
  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  &.warning {
    border-color: $color-warning;
  }

  &.valid {
    border-color: $color-select
  }

  &.active {
    display: block;
  }
}

.adventure-riddle-rectangle {
  width:0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid $color-black;
  .theme-dark & {
    border-top: 7px solid $color-white;
  }
  &.valid {
    border-top: 7px solid $color-select;
  }
}

.riddle-index {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  font-size: 15px;
  background-color: #282c34;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-right: 15px;
  .theme-dark & {
    color: #000;
  }
  .lang-rtl & {
    margin-right: 0;
    margin-left: 15px;
  }
  .valid & {
    background-color: #00d99d;
  }
}


.riddle-tips-trigger {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  font-size: 16px;
  cursor: pointer;
  background: transparent;
  color: #fff;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    background: #fff;
  }
}

.riddle-tips-trigger-input {
  position: absolute;
  width: 35px;
  height: 35px;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
  cursor: pointer;
}

.riddle-tips {
  position: absolute;
  z-index: 10;
  top: 45px;
  left: 50px;
  padding: 15px;
  background-color: #fff;
  border-radius: 15px;
  width: 40rem;
  visibility: hidden;
  font-size: 18px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  .theme-dark & {
    background-color: #000;
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
  }
  .lang-rtl & {
    right: 50px;
    left: auto;
  }
  &.active, &:hover {
    visibility: visible;
  }
}