@import "./variables/variables-gutters.scss";
@import "./variables/variables-colors.scss";
@import "./variables/variables-font-size.scss";
@import "./variables/keyframes.scss";
@import "./variables/variables-viewports.scss";

.page-content-header {
  justify-content: center;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1200px) {
    flex-direction: column;
}

@media (min-width: 1201px) {
    flex-direction: row;
}

}

.page-content-header-image {
  transform: scale(1);
  margin-top: $gutter-sm;
  transform-origin: left;
  @media (min-width: $viewport-lg) {
    margin-top: 0;
  }
  @media (min-width: $viewport-lg) {
    img {
      transform: scale(1.3);
    }
  }
}

.page-content-header-title {
  margin-top: 0;
  margin-bottom: 35px;
  font-weight: bold;
  line-height: $font-size-xxl;
  font-size: $font-size-xxl;
  br {
    display: none;
  }
  @media (min-width: $viewport-sm) {
    text-align: left;
    .lang-rtl & {
      text-align: right;
    }
    line-height: 7.7rem;
    font-size: $font-size-xxl;
  }
  @media (min-width: $viewport-lg) {
    margin-top: initial;
    white-space: nowrap;
    br {
      display: initial;
    }
  }
}

.page-content-header-description {
  font-size: $font-size-lg;
  line-height: $font-size-lg + 2;
  br {
    display: none;
  }
  @media (min-width: $viewport-lg) {
    font-size: $font-size-xl;
    line-height: $font-size-lg + 3;
  }
  @media (min-width: $viewport-lg) {
    white-space: nowrap;
    br {
      display: initial;
    }
  }
  .theme-dark & {
    color: $color-white;
  }
  .theme-light & {
    color: $color-black;
  }
}