.single-blog-side-options{
  height:50px;
  display:flex;
  flex-direction:row;
  align-items: center;
  background-color:white;
  justify-content: flex-end;
  margin: 8px 0;
  font-size: 1.5em;

  .single-blog-side-option{
    cursor: pointer;
    height: 15px;
    width: 13px;
    margin: 0 8px;
    user-select: none;
    &.preview{
      height: 20px;
      width: 15px;
    }
    &:hover{
      filter: drop-shadow(0px 0px 5px #5388ff) ;
    }
  }
}