.contact-list {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  .fullScreen & {
    border-radius: 0;
    padding: 0;
  }
}
.contact-list-contacts {
   z-index: 3;
  overflow: scroll;
  position: relative;
  top: 0;
  display: flex;
  border-radius: 15px;
  padding: 2rem 3rem;
  background-color: #282c34;
  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-track {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    display: none;
  }
}
.contact-list-list {
  width: 100%;
}
.contact-list-letters {
  width: 1.5rem;
  padding: 1rem;
  display: none;
}

/* single contact */
.contact-list-item {
  display: flex;
  align-items: center;
  //padding: 0 0 0 15px;
  border-bottom: 1px solid #fff;
  cursor: pointer;
  overflow: hidden;
}
.contact-list-item-thumbnail {
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-list-item-container {
  display: flex;
  align-items: center;
  flex: auto;
  padding: 5px;
}
.contact-list-item-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 5px;
}
.contact-list-item-name {
  font-size: 20px;
  margin: 0;
  color:#fff;
}
.contact-list-item-phone {
  font-size: 15px;
  margin: 0;
  color:#fff;
}
.contact-list-item-link {
  display: block;
  width: 100%;
  font-size: 20px;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  //padding-left: 10px;
}
