@import "./variables/variables-gutters.scss";
@import "./variables/variables-colors.scss";
@import "./variables/variables-font-size.scss";
@import "./variables/keyframes.scss";
@import "./variables/variables-viewports.scss";

.homepage-main {
  .page-content-header-description {
    @media (min-width: $viewport-lg) {
      white-space: nowrap;
    }
  }
}