@-webkit-keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes fade-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes fade-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@-webkit-keyframes scale {
    from {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    to {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes scale {
    from {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    to {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@-webkit-keyframes scale-close {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    to {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
}

@keyframes scale-close {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    to {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
}

@-webkit-keyframes slide-t {
    from {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }
    to {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes slide-t {
    from {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }
    to {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@-webkit-keyframes slide-t-close {
    from {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    to {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }
}

@keyframes slide-t-close {
    from {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    to {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }
}

@-webkit-keyframes slide-r {
    from {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
    to {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes slide-r {
    from {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
    to {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@-webkit-keyframes slide-r-close {
    from {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    to {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
}

@keyframes slide-r-close {
    from {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    to {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
}

@-webkit-keyframes slide-b {
    from {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }
    to {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes slide-b {
    from {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }
    to {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@-webkit-keyframes slide-b-close {
    from {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    to {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }
}

@keyframes slide-b-close {
    from {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    to {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }
}

@-webkit-keyframes slide-l {
    from {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
    to {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes slide-l {
    from {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
    to {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@-webkit-keyframes slide-l-close {
    from {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    to {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}

@keyframes slide-l-close {
    from {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    to {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}
@keyframes slide-l-little {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    100% {
        -webkit-transform: translateX(-20px);
        transform: translateX(-20px);

    }
}

.slider-view {
    width: 100%;
    height: 100%;
}

.slider_wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    position: relative;
    height: 100%;
}

.slider_content {
    width: 100%;
    overflow: hidden;
    position: relative;
}

.slider_item {
    width: 100%;
    height: 100%;
    position: absolute;
    text-align: center;
    -webkit-transition: opacity 1s;
    -o-transition: opacity 1s;
    transition: opacity 1s;
    right: 0;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 0;
    &.Left{
        animation: slide-l-little 3s infinite;
    }
}

.slider_item-active {
    position: relative;
    display: block;
    right: 0;
    opacity: 1;
    z-index: 1;
}

.slider_arrow {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    z-index: 10;
    text-align: center;
    cursor: pointer;
    width: 100px;
    -webkit-transition: opacity .4s;
    -o-transition: opacity .4s;
    transition: opacity .4s;
    opacity: 0;
}

.slider_arrow-left {
    left: 20px;
}

.slider_arrow-right {
    right: 20px;
}

.slider_indicators {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    z-index: 2;
}

.slider_indicators ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0 0 70px;
}

.slider_indicators_item {
    width: 12px;
    height: 12px;
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 50%;
    margin-right: 20px;
    list-style: none;
    cursor: pointer;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.slider_indicators_item-active {
    background: #000000;
    width: 13px;
    height: 13px;
}

.slider:hover .slider_arrow {
    opacity: 1;
}

.debug .slider_wrapper {
    border: 1px solid red;
}

.debug .slider_content {
    border: 1px solid blue;
}

.debug .slider_item {
    border: 1px solid #000;
}