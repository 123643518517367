.adventure-employee-final {
  text-align: center;
  a {
    color: inherit;
    text-decoration: underline;
    font-weight: bold;
  }
  p {
    font-size: 20px;
  }
}