.faq-content-wrapper {

  margin-top: 0;
  margin-bottom: 0;
  //padding: 25px 0;
  line-height: 18px;
  font-size: 20px;
  display: none;
  padding: 0px 10px;
  @media (min-width: 768px) {
    line-height: 30px;
    font-size: 28px;
    padding: 25px 40px;
  }

  &.active {
    display: block;

  }
}