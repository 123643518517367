@import "./variables/variables-gutters.scss";
@import "./variables/variables-colors.scss";
@import "./variables/variables-font-size.scss";
@import "./variables/keyframes.scss";
@import "./variables/variables-viewports.scss";

.create-tool-gallery-images-section {
  background-color: #fff;
  margin-bottom: 15px;
}
.create-tool-gallery-images-title {
  display: flex;
  align-items: center;
  h5 {
    margin: 0 0 0 10px;
  }
}
.gallery-section-header {
  background-color: #E0E0E0;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
  margin-bottom: 15px;
}

.create-tool-gallery-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e6e6e6;
  padding: 5px 10px;

  .images-container {
    align-items: center;
    padding: 5px 0;
    min-height: 0;
  }

  .image-preview-wrapper {
    padding: 0;
  }
}
.create-tool-gallery-section-body {
  border: 1px solid #e6e6e6;
  background-color: #fff;
  overflow-y: auto;
  overflow-x: auto;
  padding: $gutter-sm;

  &::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f4f4f4;
    height: .5rem;
  }

  &::-webkit-scrollbar {
    height: .5rem;
    background-color: #5693b9;
    width: .5rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #5693b9;
    height: .5rem;
  }
}
.create-tool-gallery-section-actions {
  display: flex;
  align-items:center;
}
.gallery-images-item {
  background-color: #e6e6e6;
  &:nth-child(odd) {
    background-color: #fff;
  }
}
.gallery-slider-indicator-ui {
  margin-top: 15px;
}