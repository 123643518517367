.adventure-big-thumbnail-row {
  flex-wrap: nowrap;
  width: 100%;
  min-height: 30rem;
  @media (min-width: 640px) {
    min-height: 45rem;
  }
  @media (min-width: 992px) {
    min-height: 0;
  }
}

.adventure-big-thumbnail {
  padding: 0 10px;
  min-height: 1px;
  position: absolute;
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  margin-left: 0%;
  right: auto;
  left: auto;
  transition: transform .3s;
  &:first-child {
    transform: translateX(100%);
  }
  &:last-child {
    transform: translateX(-100%);
  }
  &.active {
    transform: translateX(0);
  }

  @media (min-width: 640px) {
    padding: 0 20px;

  }
  @media (min-width: 992px) {
    position: relative;
    transform: translateX(0) !important;
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 30px;
  }
  @media (min-width: 1200px) {
    padding: 0 40px;
  }
}

.adventure-big-indicators {
  display: inline-flex;
  justify-content: center;
  background-color: #282c34;
  border-radius: 40px;
  margin-top: 15px;
  padding: 5px;
  @media (min-width: 992px) {
    display: none;
  }
}

.adventure-big-indicator {
  width: 15px;
  height: 15px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: #6c757d;
  cursor: pointer;

  &.active {
    background-color: #E0E0E0;
  }
}