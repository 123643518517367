.tool-video {
  width: 100%;
  height: 100%;;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  .iframe {
    height: 100%;
    width: 100%;
    //position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}