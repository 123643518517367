.general-css-form {
  margin: 20px 0 0 ;
}

.general-css-form-header {
  padding: 10px 20px;
  background-color: #E0E0E0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.general-css-form-body {
  padding: 10px;
  background-color: #f8f7f7;
}

.general-css-form-section {
  padding: 5px 0;
}

.general-css-form-title {
  font-size: 22px;
  font-weight: normal;
  margin: 0;
}

.general-css-form-subtitle {
  font-size: 18px;
  margin: 0 0;
}