.breadcrumbs {
  a,div {
    color: #000;
    font-size: 18px;
  }
  a:hover, div:hover {
    text-decoration: underline;
  }
  span {
    text-decoration: none !important;
  }
}