.create-tool-gallery {
  .advanced-numbers {
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
    margin-bottom: 15px;
    background-color: #fff;
    border-radius: 10px;
    h5 {
      width: 100%;
      margin-top: 0;
    }
  }
  .advanced-numbers-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .advanced-numbers-item {
    padding: 0 15px;
    min-width: 120px;
    max-width: 12.5%;
  }
  .gallery-details {
    margin-top: 20px;
    > ul {
      padding-right: 0;
    }
  }
  .gallery-details-fields {
    display: flex;
  }
  .gallery-add {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.create-tool-gallery-section {
  padding: 18px 15px;
  border-bottom: 1px solid #7b7a7d;
  border-top: 1px solid  #7b7a7d;
  background-color: #fff;
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
}
.gallery-section-header {
  background-color: #E0E0E0;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
  margin-bottom: 15px;
}