.table-inner-menu {
  position: absolute;
  left: -10px;
  z-index: 2;
  top: 50px;
}

.table-inner-menu-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding:  5px 10px 0 0px;
  &:hover {
    background-color: #eaf1f4;
  }
}

.table-inner-menu-icon {
  padding:  0 10px;
}

.table-inner-menu-name {
  padding:  0 10px;
  white-space: nowrap;
  margin: 5px 0;
  font-size: 16px;
}