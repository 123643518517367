.tool-gallery-section {
  display: none;
  position: relative;

  &.view-slider {
    overflow-y: hidden;
    overflow-x: auto;
    height: 270px;
    position: relative;
    &::-webkit-scrollbar {
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      margin-top: 20px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #bbbbbc;
      border-radius: 10px;
      width: 50px
    }
  }
  &.active {
    display: block;
  }
  .iframe {
    height: 100%;
  }
}

.tool-gallery-images {
  max-width: 100%;
  //max-height: 100%;
  width: 100%;

  .view-slider & {
  }
}

.tool-gallery-images-icons {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 30px;

  &.active {
    flex-direction: row;
  }
}

.tool-gallery-icon {
  width: 250px;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    transform: scale(1.2);
  }

  .active & {
    width: 100px;
  }
}

.tool-gallery-section-images {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  overflow-x: auto;
  height: 100%;
  .view-slider & {
    margin: 0 auto;
    position: absolute;
    @media (min-width: 640px) {
      //max-width: 80vw;
    }
  }

  .view-blocks &,   .view-grid & {
    max-width: 500px;
    margin: 0 auto;
  }
  &::-webkit-scrollbar {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    margin-top: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bbbbbc;
    border-radius: 10px;
    width: 50px
  }

  .view-grid & {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .view-slider & {
    flex-direction: row;
    align-items: center;
  }
}

.tool-gallery-section-icon {
  text-align: center;
}

.tool-gallery-item {
  display: flex;
  justify-content: center;
  align-items: center;
  //padding: 10px;
  .view-blocks & {
    margin-bottom: 20px;
  }
  .view-grid & {
    width: 50%;
    padding: 5px;
  }

  .view-slider & {
    margin-left: 15px;
    width: 180px;
    flex: 180px;
  }
}

.gallery-item-frame {
  width: 250px;
  max-width: 600px;
  position: relative;
  overflow: hidden;

  .view-blocks & {
    width: 100%;
  }

  .view-carrousel & {
    width: 100%;
  }
}

.gallery-advanced-data {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  padding: 10px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-track {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    display: none;
  }
  ul {
    padding: 0;
    list-style: none;
  }
}

.gallery-item-image {
  width:100%;
  top: 0;
  left: 0;
  height: 100%;
  //background-color: #fff;
  position: relative;
  display: flex;
  align-items: center;
}

// Style for matching view
.tool-gallery-section.view-matching {
  position: relative;

  .tool-gallery-matching-lines {
    position: relative;
    z-index: 1;
    padding: 70px;
    width: 100%

  }

  .tool-gallery-section-images {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 20px;

    img {
      display: block;
    }
  }

  .gallery-item-image {
    display: none;
  }

  .tool-gallery-item {
    position: relative;
    width: 26%;
    height: 40px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center left;


    .gallery-item-frame {
      border-radius: 0;
    }

    &:first-child {
      top: 48px;
      right: 10px
    }

    &:nth-child(2) {
      top: 30px;
      left: 10px;
    }

    &:nth-child(3) {
      top: 49px;
      left: 42px;
    }

    &:nth-child(4) {
      top: 50px;
      right: 20px;
    }

    &:nth-child(5) {
      top: 34px;
    }

    &:nth-child(6) {
      top: 34px;
      left: 20px;
    }

    &:nth-child(7) {
      top: 19px;
      right: -18px;
    }

    &:nth-child(8) {
      top: 20px;
      left: 19px;
    }

    &:nth-child(9) {
      top: 37px;
      left: 10px;
    }

    &:nth-child(10) {
      top: 33px;
      right: -19px;
    }

    &:nth-child(11) {
      top: 60px;
      left: 13px;
    }

    &:nth-child(12) {
      top: 8px;
      left: -18px;
    }

    &:nth-child(13) {
      top: 15px;
      right: 45px;
    }

    &:nth-child(14) {
      top: -12px;
      left: -87px;
    }
  }
}