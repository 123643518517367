@import "./variables/variables-gutters.scss";
@import "./variables/variables-colors.scss";
@import "./variables/variables-font-size.scss";
@import "./variables/keyframes.scss";
@import "./variables/variables-viewports.scss";

.tool-puzzle {
  background-repeat: no-repeat;
  background-size: cover;
  padding: $gutter-sm;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.tool-puzzle-container {
  padding-bottom: 15px;
  padding-top: 15px;
  background-size: cover;
  position: relative;
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tool-puzzle-level-buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.tool-puzzle-level-button {
  display: flex;
  justify-content: center;
  button {
    border: 0;
    font-size: 15px;
    font-weight: bold;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.tool-puzzle-score {
  border-radius: 50%;
  background-color: lightBlue;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  font-size: 22px;
  margin: 0 auto 20px;
}