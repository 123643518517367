


@import "./variables/variables-gutters.scss";
@import "./variables/variables-colors.scss";
@import "./variables/variables-font-size.scss";
@import "./variables/keyframes.scss";
@import "./variables/variables-viewports.scss";

.frame {
  display: flex;
  align-items: center;
  position: relative;
  overflow: visible;
  &.active {

  }
}
.frame-loading {
  width: 100%;
  height: 46px;
  background-color: #f0f0f0;
  margin-bottom: 15px;
}
.frame-header {
  display: flex;
  align-items: center;
  background-color: #e6e6e6;
  padding: 15px 10px;
  font-size: 20px;
  width: 100%;
  border-radius: 5px;
  border: 2px solid #e6e6e6;
  .frame.active & {
    background-color: #fff;
  }
}
.frame-header-item {
  @media (min-width:768px) {
    width: 20%;
  }
}
.frame-index {
  padding: 0 10px;
  font-size: 14px;
}
.frame-title {}
.frame-title, .frame-tool-name, .frame-tool-editable {
  margin: 0;
  padding: 0 5px;
}
.frame-thumbnail {
  width: 60px;
  padding: 0 15px;
  display: none;
  align-items: center;
  @media (min-width:640px) {
    display: flex;
  }
}
.frame-actions {
  display: flex;
  align-items: center;
  margin-right: auto;
}
.frame-action-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0 !important;
  margin-right: 10px;
  background-color: transparent;
  cursor: pointer;
  border-radius: 50%;
  outline: 0;
  padding: 0;
  width: 30px;
  height: 30px;
  &.focus {
    background-color: #E0E0E0;
  }
  &:focus {
    border:0;
  }
}
.frame-header-right {
  display: flex;
  align-items: center;
  @media (min-width:768px) {
    width: 32%;
  }

}
.frame-drag-handle {
  display: flex;
  align-items: center;
}
.frame-body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #f4f4f4;
  position: relative;
  z-index: 1;
  &::-webkit-scrollbar {
    background-color: rgba(0,0,0,0.3);
    margin-top: 20px;
    width: 5px
  }
  &::-webkit-scrollbar-track {
    width: 5px
  }
  &::-webkit-scrollbar-thumb {
    background-color: #333333;
    border-radius: 2px;
    width: 5px
  }
}
.frame-body-close {
  position: absolute;
  top: 10px;
  left: 10px;
}
.single-frame-preview-wrapper {
  display: flex;
  width: 100%;
}
.single-frame-preview {
  //width: 400px;
 }
.single-frame-section {
  .images-container {
    justify-content: flex-start;
  }
}
.single-frame-header {
  padding: 15px;
}
.frame-tool-wrapper {
  min-height: 400px;
  margin-top: 15px;
  max-width: 100%;
  overflow: auto;
}
.tool-add-section {
  display: block;
  margin: 20px auto 0;
  border:0;
}
.frame-fields-group {
  display: flex;
  max-width:700px;
  align-items: center;
}
.frame-hr {
  margin: 20px 0;
  height: 1px;
  background-color: #000;
  width: 100%;
}
.frame-loading {
  height: 60px;
  width: 100%;
  background-color: #e6e6e6;

}