.adventure-type-311 {
  .lobby-circle {
    overflow:hidden;

    margin: 30px auto;
    padding: 0;
    width: 30rem; height: 30rem;
    border-radius: 50%;
    list-style: none;
    border: 2px solid #000;
    @media (min-width:640px) {
      //width: 24rem; height: 24rem;
    }
    @media (min-width:768px) {
      position: absolute;
      width: 30rem; height: 30rem;
      transform: translateY(-135px);
    }
  }
  .slice {
    overflow: hidden;
    position: absolute;
    top: 0; right: 0;
    width: 50%; height: 50%;
    transform-origin: 0% 100%;
    opacity: 1;
    &.transparent {
      opacity: 0;
    }
  }
  .slice-contents {
    position: absolute;
    left: -100%;
    width: 200%; height: 200%;
    border-radius: 50%;
    background: #a6dcea;
  }

  .lobby-circle-final {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 250px;
    top: 0;
    @media (min-width:768px) {
      position: absolute;
    }
    h3 {
      text-align: center;
      font-size: 20px;
      margin: 0;
    }
  }
}
