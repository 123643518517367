@import "./variables/variables-gutters.scss";
@import "./variables/variables-colors.scss";
@import "./variables/variables-font-size.scss";
@import "./variables/keyframes.scss";
@import "./variables/variables-viewports.scss";

.user-progress-adventures {
  max-height: 600px;
  overflow-y: auto;
  padding: 20px 20px 0;
  max-width: 28rem;
  .theme-dark & {
    background-color: #000;
  }
  @media (min-width: $viewport-sm) {
    max-width: 50rem;
  }
  h5 {
    margin: 0
  }
  & + .navigation {
    box-shadow: 0 0 2.2px rgba(0, 0, 0, .3);
    z-index: 1;
    .theme-dark & {
      background-color: #000;
      box-shadow: 0 0 2.2px rgba(255, 255, 255, .3);
    }
  }
  .nav-item {
    padding: 0;
  }
  .screen-user-item {
    margin: 15px 0;
    cursor: pointer;
    &.minimized {
      &:hover {
        .screen-user-item-details-header {
          background-color: $gray-100;
          .theme-dark & {
            background-color: $gray-600;
          }
        }
      }
    }
    input {
      padding-top: 0;
      padding-bottom: 0;
      font-size: 17px;
    }
    p {
      margin-bottom: 0;
      font-size: 14px;
    }
    * {
      cursor: pointer;
    }
  }
}