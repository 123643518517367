.single-frame-modals {
  .frame-tool-wrapper {
      min-height:auto;
      margin: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom:0;
  }
}

.single-frame-add-modal {
  margin-top: 15px;
}
.single-frame-modal {
  background-color: #f8f7f7;
  margin-bottom: 10px;
  padding-bottom: 1px;
  padding-left: 15px;
  padding-right: 15px;
}