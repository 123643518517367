@import "./variables/variables-gutters.scss";
@import "./variables/variables-colors.scss";
@import "./variables/variables-font-size.scss";
@import "./variables/keyframes.scss";
@import "./variables/variables-viewports.scss";
.create-adventure {
  //min-height: 500px;
  &.loading{
  height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.english {
    * {
      direction: ltr;
    }
  }
}
.create-adventure-container {
  padding: 30px 0;
}
.create-adventure-header {
  padding: 10px 0;
  text-align: center;
  @media (min-width: 768px) {
    padding: 30px 0;
  }
}

.create-adventure-title {
  p {
    font-size: $font-size-lg;
    text-align: right;
    margin: 0;
    color: #000;
    .theme-dark & {
      color: #fff;
    }
  }
}

.create-adventure-thumbnail {
  .preview {
    width: 200px;
  }
}

.create-adventure-title-field {
  input {
    border-radius: 0;
    padding: 0;
    border-bottom: 2px solid transparent !important;
    width: 100%;
    height: auto;
    font-size: $font-size-lg;
    text-align: right;
    color: $color-info;
    transition: border-bottom-color .3s;
    @media (min-width: $viewport-xs) {
      font-size: $font-size-xxl;
    }
    @media (min-width: $viewport-sm) {
      font-size: $font-size-xxl;
    }

    &:hover {
      border-bottom: 2px solid $gray-300 !important;
    }
    &:focus {
      border-bottom: 2px solid #00a2ff !important;
    }
  }
}
.create-adventure-edit-title {
  color:#fff
}
.create-adventure-continue {
  margin: 0 auto;
  width: 230px;
  color: #fff;
  display: block;
}
.create-adventure-payment {
  background-color: $gray-100;
  position: relative;
  padding: 40px;
  border-radius: 30px;
  overflow: hidden;
  margin: 30px 0;
  box-shadow: 0 0 6px rgba(0,0,0,0.5);
  .theme-dark & {
    background-color: #292929;
  }
}


.create-adventure-passcode {
  text-align: center;
  font-size: 30px;
  p {
    margin: 0
  }
}

.create-adventure-admin-frame {
  position: fixed;
  top: 70px;
  left: 0;
  width: 100%;
  z-index: 200;
  height: calc(100vh - 70px);
  transform: translateY(100vh);
  transition: transform .3s;
  &.active {
    transform: translateY(0);
  }
}


.create-adventure-payment-warning {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 15px;
}
.create-adventure-payment-warning-buttons {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;

  button {
    font-weight: bold;
    margin: 0 10px;
  }
}