@import "./variables/variables-gutters.scss";
@import "./variables/variables-colors.scss";
@import "./variables/variables-font-size.scss";
@import "./variables/keyframes.scss";
@import "./variables/variables-viewports.scss";

.riddle-instructions {
  text-align: center;
}

.riddle-instructions-title {
  //font-size: $font-size-sm;
  //margin: 0;
  text-align: right;
  @media (min-width: $viewport-sm) {
    //font-size: $font-size-md;
  }
}

.riddle-instructions-subtitle {
  font-size: $font-size-xs;
  font-weight: normal;
  margin: $gutter-xs 0;
  text-align: right;
  @media (min-width: $viewport-sm) {
    font-size: $font-size-xs;
  }
}

.riddle-instructions-description {
  font-size: $font-size-xs;
}