/* body {
    margin: 0;
    padding: 0;
    background: #000;
    overflow: hidden;
} */

.pyro>.before,
.pyro>.after {
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    box-shadow: 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff;
    -moz-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
    -webkit-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
    -o-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
    -ms-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
    animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
}

.pyro>.after {
    -moz-animation-delay: 1.25s, 1.25s, 1.25s;
    -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
    -o-animation-delay: 1.25s, 1.25s, 1.25s;
    -ms-animation-delay: 1.25s, 1.25s, 1.25s;
    animation-delay: 1.25s, 1.25s, 1.25s;
    -moz-animation-duration: 1.25s, 1.25s, 6.25s;
    -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
    -o-animation-duration: 1.25s, 1.25s, 6.25s;
    -ms-animation-duration: 1.25s, 1.25s, 6.25s;
    animation-duration: 1.25s, 1.25s, 6.25s;
}

@-webkit-keyframes bang {
    to {
        box-shadow: 228px 54.3333333333px #ff1a00, -157px -221.6666666667px #f09, -28px -54.6666666667px #009dff, 141px -101.6666666667px #ff2600, 89px 67.3333333333px #0f2, -177px -106.6666666667px #1f0, 130px -239.6666666667px #00ff15, -69px -172.6666666667px #50f, -91px 49.3333333333px #00ffa2, 37px -207.6666666667px #1f0, -107px 26.3333333333px #ff5900, -98px -198.6666666667px #002bff, -114px -11.6666666667px #00ffe6, -119px -138.6666666667px #00ff2b, 125px 12.3333333333px #00ff04, -55px -315.6666666667px #ff002f, -204px -36.6666666667px #1eff00, -149px -207.6666666667px #ff003c, -13px -100.6666666667px #26ff00, -187px -346.6666666667px #f80, -12px -404.6666666667px #f06, 162px 79.3333333333px #80f, -98px -331.6666666667px #ffe600, 39px -204.6666666667px #a6ff00, 111px 27.3333333333px #0095ff, 132px 77.3333333333px #00b7ff, -185px -382.6666666667px #ef0, 41px 3.3333333333px #ff0095, 95px 63.3333333333px #ff00fb, 126px -64.6666666667px #ff00d9, -242px -92.6666666667px #2f0, 96px -53.6666666667px #00ff59, -134px -408.6666666667px #00ff2f, -221px -142.6666666667px #0dff00, 228px -166.6666666667px #fc0, 38px -324.6666666667px #c400ff, -104px -167.6666666667px #0400ff, 8px -282.6666666667px #cf0, -169px -329.6666666667px #f0b, 237px 59.3333333333px #ffa200, 192px -30.6666666667px #9dff00, 207px -270.6666666667px #ff00e6, 1px -148.6666666667px #48ff00, -90px -157.6666666667px #ff0040, 64px -166.6666666667px #00a2ff, 178px 50.3333333333px #fa0, -156px -205.6666666667px #ff00e6, 18px -134.6666666667px #ffe100, -8px -126.6666666667px #3c00ff, -85px -113.6666666667px #0dff00, 218px -226.6666666667px #00ff73;
    }
}

@-moz-keyframes bang {
    to {
        box-shadow: 228px 54.3333333333px #ff1a00, -157px -221.6666666667px #f09, -28px -54.6666666667px #009dff, 141px -101.6666666667px #ff2600, 89px 67.3333333333px #0f2, -177px -106.6666666667px #1f0, 130px -239.6666666667px #00ff15, -69px -172.6666666667px #50f, -91px 49.3333333333px #00ffa2, 37px -207.6666666667px #1f0, -107px 26.3333333333px #ff5900, -98px -198.6666666667px #002bff, -114px -11.6666666667px #00ffe6, -119px -138.6666666667px #00ff2b, 125px 12.3333333333px #00ff04, -55px -315.6666666667px #ff002f, -204px -36.6666666667px #1eff00, -149px -207.6666666667px #ff003c, -13px -100.6666666667px #26ff00, -187px -346.6666666667px #f80, -12px -404.6666666667px #f06, 162px 79.3333333333px #80f, -98px -331.6666666667px #ffe600, 39px -204.6666666667px #a6ff00, 111px 27.3333333333px #0095ff, 132px 77.3333333333px #00b7ff, -185px -382.6666666667px #ef0, 41px 3.3333333333px #ff0095, 95px 63.3333333333px #ff00fb, 126px -64.6666666667px #ff00d9, -242px -92.6666666667px #2f0, 96px -53.6666666667px #00ff59, -134px -408.6666666667px #00ff2f, -221px -142.6666666667px #0dff00, 228px -166.6666666667px #fc0, 38px -324.6666666667px #c400ff, -104px -167.6666666667px #0400ff, 8px -282.6666666667px #cf0, -169px -329.6666666667px #f0b, 237px 59.3333333333px #ffa200, 192px -30.6666666667px #9dff00, 207px -270.6666666667px #ff00e6, 1px -148.6666666667px #48ff00, -90px -157.6666666667px #ff0040, 64px -166.6666666667px #00a2ff, 178px 50.3333333333px #fa0, -156px -205.6666666667px #ff00e6, 18px -134.6666666667px #ffe100, -8px -126.6666666667px #3c00ff, -85px -113.6666666667px #0dff00, 218px -226.6666666667px #00ff73;
    }
}

@-o-keyframes bang {
    to {
        box-shadow: 228px 54.3333333333px #ff1a00, -157px -221.6666666667px #f09, -28px -54.6666666667px #009dff, 141px -101.6666666667px #ff2600, 89px 67.3333333333px #0f2, -177px -106.6666666667px #1f0, 130px -239.6666666667px #00ff15, -69px -172.6666666667px #50f, -91px 49.3333333333px #00ffa2, 37px -207.6666666667px #1f0, -107px 26.3333333333px #ff5900, -98px -198.6666666667px #002bff, -114px -11.6666666667px #00ffe6, -119px -138.6666666667px #00ff2b, 125px 12.3333333333px #00ff04, -55px -315.6666666667px #ff002f, -204px -36.6666666667px #1eff00, -149px -207.6666666667px #ff003c, -13px -100.6666666667px #26ff00, -187px -346.6666666667px #f80, -12px -404.6666666667px #f06, 162px 79.3333333333px #80f, -98px -331.6666666667px #ffe600, 39px -204.6666666667px #a6ff00, 111px 27.3333333333px #0095ff, 132px 77.3333333333px #00b7ff, -185px -382.6666666667px #ef0, 41px 3.3333333333px #ff0095, 95px 63.3333333333px #ff00fb, 126px -64.6666666667px #ff00d9, -242px -92.6666666667px #2f0, 96px -53.6666666667px #00ff59, -134px -408.6666666667px #00ff2f, -221px -142.6666666667px #0dff00, 228px -166.6666666667px #fc0, 38px -324.6666666667px #c400ff, -104px -167.6666666667px #0400ff, 8px -282.6666666667px #cf0, -169px -329.6666666667px #f0b, 237px 59.3333333333px #ffa200, 192px -30.6666666667px #9dff00, 207px -270.6666666667px #ff00e6, 1px -148.6666666667px #48ff00, -90px -157.6666666667px #ff0040, 64px -166.6666666667px #00a2ff, 178px 50.3333333333px #fa0, -156px -205.6666666667px #ff00e6, 18px -134.6666666667px #ffe100, -8px -126.6666666667px #3c00ff, -85px -113.6666666667px #0dff00, 218px -226.6666666667px #00ff73;
    }
}

@-ms-keyframes bang {
    to {
        box-shadow: 228px 54.3333333333px #ff1a00, -157px -221.6666666667px #f09, -28px -54.6666666667px #009dff, 141px -101.6666666667px #ff2600, 89px 67.3333333333px #0f2, -177px -106.6666666667px #1f0, 130px -239.6666666667px #00ff15, -69px -172.6666666667px #50f, -91px 49.3333333333px #00ffa2, 37px -207.6666666667px #1f0, -107px 26.3333333333px #ff5900, -98px -198.6666666667px #002bff, -114px -11.6666666667px #00ffe6, -119px -138.6666666667px #00ff2b, 125px 12.3333333333px #00ff04, -55px -315.6666666667px #ff002f, -204px -36.6666666667px #1eff00, -149px -207.6666666667px #ff003c, -13px -100.6666666667px #26ff00, -187px -346.6666666667px #f80, -12px -404.6666666667px #f06, 162px 79.3333333333px #80f, -98px -331.6666666667px #ffe600, 39px -204.6666666667px #a6ff00, 111px 27.3333333333px #0095ff, 132px 77.3333333333px #00b7ff, -185px -382.6666666667px #ef0, 41px 3.3333333333px #ff0095, 95px 63.3333333333px #ff00fb, 126px -64.6666666667px #ff00d9, -242px -92.6666666667px #2f0, 96px -53.6666666667px #00ff59, -134px -408.6666666667px #00ff2f, -221px -142.6666666667px #0dff00, 228px -166.6666666667px #fc0, 38px -324.6666666667px #c400ff, -104px -167.6666666667px #0400ff, 8px -282.6666666667px #cf0, -169px -329.6666666667px #f0b, 237px 59.3333333333px #ffa200, 192px -30.6666666667px #9dff00, 207px -270.6666666667px #ff00e6, 1px -148.6666666667px #48ff00, -90px -157.6666666667px #ff0040, 64px -166.6666666667px #00a2ff, 178px 50.3333333333px #fa0, -156px -205.6666666667px #ff00e6, 18px -134.6666666667px #ffe100, -8px -126.6666666667px #3c00ff, -85px -113.6666666667px #0dff00, 218px -226.6666666667px #00ff73;
    }
}

@keyframes bang {
    to {
        box-shadow: 228px 54.3333333333px #ff1a00, -157px -221.6666666667px #f09, -28px -54.6666666667px #009dff, 141px -101.6666666667px #ff2600, 89px 67.3333333333px #0f2, -177px -106.6666666667px #1f0, 130px -239.6666666667px #00ff15, -69px -172.6666666667px #50f, -91px 49.3333333333px #00ffa2, 37px -207.6666666667px #1f0, -107px 26.3333333333px #ff5900, -98px -198.6666666667px #002bff, -114px -11.6666666667px #00ffe6, -119px -138.6666666667px #00ff2b, 125px 12.3333333333px #00ff04, -55px -315.6666666667px #ff002f, -204px -36.6666666667px #1eff00, -149px -207.6666666667px #ff003c, -13px -100.6666666667px #26ff00, -187px -346.6666666667px #f80, -12px -404.6666666667px #f06, 162px 79.3333333333px #80f, -98px -331.6666666667px #ffe600, 39px -204.6666666667px #a6ff00, 111px 27.3333333333px #0095ff, 132px 77.3333333333px #00b7ff, -185px -382.6666666667px #ef0, 41px 3.3333333333px #ff0095, 95px 63.3333333333px #ff00fb, 126px -64.6666666667px #ff00d9, -242px -92.6666666667px #2f0, 96px -53.6666666667px #00ff59, -134px -408.6666666667px #00ff2f, -221px -142.6666666667px #0dff00, 228px -166.6666666667px #fc0, 38px -324.6666666667px #c400ff, -104px -167.6666666667px #0400ff, 8px -282.6666666667px #cf0, -169px -329.6666666667px #f0b, 237px 59.3333333333px #ffa200, 192px -30.6666666667px #9dff00, 207px -270.6666666667px #ff00e6, 1px -148.6666666667px #48ff00, -90px -157.6666666667px #ff0040, 64px -166.6666666667px #00a2ff, 178px 50.3333333333px #fa0, -156px -205.6666666667px #ff00e6, 18px -134.6666666667px #ffe100, -8px -126.6666666667px #3c00ff, -85px -113.6666666667px #0dff00, 218px -226.6666666667px #00ff73;
    }
}

@-webkit-keyframes gravity {
    to {
        transform: translateY(200px);
        -moz-transform: translateY(200px);
        -webkit-transform: translateY(200px);
        -o-transform: translateY(200px);
        -ms-transform: translateY(200px);
        opacity: 0;
    }
}

@-moz-keyframes gravity {
    to {
        transform: translateY(200px);
        -moz-transform: translateY(200px);
        -webkit-transform: translateY(200px);
        -o-transform: translateY(200px);
        -ms-transform: translateY(200px);
        opacity: 0;
    }
}

@-o-keyframes gravity {
    to {
        transform: translateY(200px);
        -moz-transform: translateY(200px);
        -webkit-transform: translateY(200px);
        -o-transform: translateY(200px);
        -ms-transform: translateY(200px);
        opacity: 0;
    }
}

@-ms-keyframes gravity {
    to {
        transform: translateY(200px);
        -moz-transform: translateY(200px);
        -webkit-transform: translateY(200px);
        -o-transform: translateY(200px);
        -ms-transform: translateY(200px);
        opacity: 0;
    }
}

@keyframes gravity {
    to {
        transform: translateY(200px);
        -moz-transform: translateY(200px);
        -webkit-transform: translateY(200px);
        -o-transform: translateY(200px);
        -ms-transform: translateY(200px);
        opacity: 0;
    }
}

@-webkit-keyframes position {
    0%,
    19.9% {
        margin-top: 10%;
        margin-left: 40%;
    }
    20%,
    39.9% {
        margin-top: 40%;
        margin-left: 30%;
    }
    40%,
    59.9% {
        margin-top: 20%;
        margin-left: 70%;
    }
    60%,
    79.9% {
        margin-top: 30%;
        margin-left: 20%;
    }
    80%,
    99.9% {
        margin-top: 30%;
        margin-left: 80%;
    }
}

@-moz-keyframes position {
    0%,
    19.9% {
        margin-top: 10%;
        margin-left: 40%;
    }
    20%,
    39.9% {
        margin-top: 40%;
        margin-left: 30%;
    }
    40%,
    59.9% {
        margin-top: 20%;
        margin-left: 70%;
    }
    60%,
    79.9% {
        margin-top: 30%;
        margin-left: 20%;
    }
    80%,
    99.9% {
        margin-top: 30%;
        margin-left: 80%;
    }
}

@-o-keyframes position {
    0%,
    19.9% {
        margin-top: 10%;
        margin-left: 40%;
    }
    20%,
    39.9% {
        margin-top: 40%;
        margin-left: 30%;
    }
    40%,
    59.9% {
        margin-top: 20%;
        margin-left: 70%;
    }
    60%,
    79.9% {
        margin-top: 30%;
        margin-left: 20%;
    }
    80%,
    99.9% {
        margin-top: 30%;
        margin-left: 80%;
    }
}

@-ms-keyframes position {
    0%,
    19.9% {
        margin-top: 10%;
        margin-left: 40%;
    }
    20%,
    39.9% {
        margin-top: 40%;
        margin-left: 30%;
    }
    40%,
    59.9% {
        margin-top: 20%;
        margin-left: 70%;
    }
    60%,
    79.9% {
        margin-top: 30%;
        margin-left: 20%;
    }
    80%,
    99.9% {
        margin-top: 30%;
        margin-left: 80%;
    }
}

@keyframes position {
    0%,
    19.9% {
        margin-top: 10%;
        margin-left: 40%;
    }
    20%,
    39.9% {
        margin-top: 40%;
        margin-left: 30%;
    }
    40%,
    59.9% {
        margin-top: 20%;
        margin-left: 70%;
    }
    60%,
    79.9% {
        margin-top: 30%;
        margin-left: 20%;
    }
    80%,
    99.9% {
        margin-top: 30%;
        margin-left: 80%;
    }
}