.crop-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 80px;
}

.controls {
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    left: 0%;
    width: 100%;
    padding: 0 16px;
    height: 80px;
    display: flex;
    align-items: center;
}
