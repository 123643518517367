@import "./variables/variables-gutters.scss";
@import "./variables/variables-colors.scss";
@import "./variables/variables-font-size.scss";
@import "./variables/keyframes.scss";
@import "./variables/variables-viewports.scss";

.images-container {
  width: 100%;
  max-height: 450px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-weight: bold;
  min-height: 0;
}

.images-trigger {
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 3rem;
  width: 154px;
  height: 44px;
  padding: 0;
  transition: width 0.4s;

  &.add {
    width: 44px;
    height: 44px;
  }
}

.images-trigger p {
  margin-top: 0;
  margin-bottom: 0;
  color: black;
  font-size: 18px;
}

.image-preview-default-text {
  opacity: 0;
  visibility: hidden;
}

@media (min-width: $viewport-sm) {
  .preview .images-trigger {
    position: absolute;
  }
}

.images-trigger span {
  padding-right: 0;
  cursor: pointer;
  text-align: center;
}

.images-trigger input {
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.images-container.preview {
  justify-content: center;
  align-items: center;
  /* border: 1px solid; */
}

.image-preview-wrapper {
  position: relative;
  width: 150px;
  height: 150px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-preview-wrapper {
  width: 50%;
}

@media (min-width: $viewport-xs) {
  .image-preview-wrapper {
    width: 33.33%;
  }
}

@media (min-width: $viewport-sm) {
  .image-preview-wrapper {
    width: 33.33%;
  }
}

@media (min-width: $viewport-md) {
  .image-preview-wrapper {
    width: 20%;
  }
}

@media (min-width: $viewport-lg) {
  .image-preview-wrapper {
    width: 16.6667%;
  }
}

.preview-wrapper {
  position: relative;
}

.image-preview-image {
  /* display: none; */
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;

  .route-admin & {
    background-size: contain;
    background-position: center center;
  }
}

.image-preview-image.small {
  border-radius: 0;
}

.image-preview-loading {
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.image-preview-actions {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.2s;
  display: block;
  padding: $gutter-xxs $gutter-xs;
  background-color: transparent;
  &.small {
    display: flex;
  }

  .image-preview-image.small .image-preview-actions {
    padding: 0;
  }

  .image-preview-actions-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: auto;
    text-align: left;
    cursor: pointer;
    padding: 4px 0;
    .small & {
      padding: 2px 0;
    }
  }

  .image-preview-image.small .image-preview-actions-btn {
    height: 100%;
  }

  .image-preview-actions-btn button {
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    padding: 0;
    background-color: $color-action;
    color: $color-white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.2rem solid #fff;
    .small & {
      width: 2.6rem;
      height: 2.6rem;
    }
    .theme-dark & {
      border: 0.2rem solid #000;
    }
  }

  .image-preview-image.small .image-preview-actions-btn button {
    border-radius: 0;
  }
}

.image-preview-actions:hover {
  opacity: 1;
}

.placeholder-button {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;

  span {
    width: 100%;
  }
}

.placeholder-button-wrapper {
  height: 130px;
  border: 2px dashed #b5aeaf;
  border-radius: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  background-color: #f2f2f2;
}

.placeholder-button-wrapper:hover {
  background-color: #ffffff;
  .theme-dark & {
    background-color: #000000;
  }
}

.placeholder-button input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  cursor: pointer;
  z-index: 2;
}

.files-optional-images-item {
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}
