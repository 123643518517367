.singleDifficultyLevelOption {
  margin: 0 2px;
  user-select: none;

  &:hover {
    cursor: pointer;
  }
}

.riddles-store-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #000;
  border-radius: 50%;
  margin: 0 10px;
  cursor: pointer;
  background-color: #fff;
  color: #000;

  &:hover {
    background-color: #000;
    color: #fff;
  }

  &.disabled {
    opacity: .5;
    cursor: default;

    &:hover {
      background-color: #fff;
      color: #000;
    }
  }
}

.riddle-store {
  width: 100%;
  position: relative;
}


.riddles-store-filter-wrapper {
  position: fixed;
  width: 100%;
  @media (min-width: 1200px) {
    .riddle-store.sticky & {
      top: 80px;
      position: fixed !important;
      @media (min-width: 1600px) {
        padding-left: 100px !important;
      }

      + .riddles-store-riddles-wrapper {
        margin-right: 25%;
      }
    }
  }
}