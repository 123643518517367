.adventure-type-item {
  .choose-adventure-template {
    * {
      border: 0;
      outline: none
    }
  }

  .inner-menu-trigger {
    position: relative;

    .inner-menu-trigger-input {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0;
      cursor: pointer;
    }
  }

  input[type="text"], input[type="number"], select {
    border-bottom: 1px solid transparent !important;
    border-radius: 0;

    &:hover {
      border-bottom: 1px solid #b3b3b3 !important;
    }

    &:focus {
      border-bottom: 2px solid #5388ff !important;
    }
  }
}

.page-loading {
  position: fixed;
  top:0;
  right: 0;
  bottom: 0;
  left:0;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
}