$font-size-min: 1.3rem;
$font-size-xxs: 1.5rem;
$font-size-xs: 1.6rem;
$font-size-base: 1.8rem;
$font-size-sm: 2rem;
$font-size-md: 2.4rem;
$font-size-lg: 2.8rem;
$font-size-xl: 3.8rem;
$font-size-xxl: 7.2rem;
$font-size-xxxl: 9rem;
$font-size-jumbo: 11.2rem;
$font-size-max: 20rem;