.choose-tool-item {
  width: 70px;
  padding: 5px 10px 10px;
  p {
    line-height: 18px;
  }
  &:hover {
    p {
      font-weight: bold;
    }
  }
}