.container-drag {
    text-align: center;
    position: relative;

    width: 100%;
    display: flex;
}

.riddles-list {
    width: 35%;
    border: 1px solid #000;
}
.riddles-list-item {
    background-color: #f2f2f2;
    cursor: pointer;
    font-size: 12px;
    margin-bottom: 5px;
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: space-between;
    align-items: center;
}
.riddles-list-item:hover {
     background-color: #f2fafd;
 }
.active-riddles-list {
width:65%;
    border: 1px solid #000;
}


.active-riddle-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 5px 10px;
}
.active-riddle-item .edit-button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    padding: 0;
}