.images-container {
    width: 100%;
    min-height: 70px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* align-items: center; */
    /* margin: 0 -10px; */
    font-weight: bold;
    color: #cccccc;
    font-size: 1.6rem
}

.images-trigger {
    /* background-color: teal; */
    border: 0;
    /* width: 120px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 30px;
    border-radius: 2.6px;
    color: #000;
    position: relative;
    cursor: pointer;
}

.preview .images-trigger {
    position: static;
    width: 100%;
    height: 100%;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    /*padding-top: 30px;*/
    /*padding-bottom: 30px;*/
}

.image-preview-default-text {
    opacity: 0;
    visibility: hidden;
}

@media (min-width: 768px) {
    .preview .images-trigger {
        position: absolute;
    }
}

.images-trigger span {
    padding-right: 5px;
    cursor: pointer;
}

.images-trigger input {
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
}

.images-container.preview {
    justify-content: center;
    align-items: center;
    /* border: 1px solid; */
}

.image-preview-wrapper {
    position: relative;
    width: 50%;
    height: 150px;
    padding: 10px;
}

@media (min-width: 768px) {
    .image-preview-wrapper {
        width: 33.33%;
    }
}

@media (min-width: 992px) {
    .image-preview-wrapper {
        width: 20%;
    }
}

.preview-wrapper {
    position: relative;
}

.image-preview-image {
    /* display: none; */
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #fff;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
}

.preview .image-preview-image {
    border: 1px dashed #999;
}

.image-preview-loading+.image-preview-image {
    opacity: .5;
}

.image-preview-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-preview-actions {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
    transition: opacity .2s;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.6);
}

.image-preview-actions-btn {
    width: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-preview-actions-btn button {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 0;
    background-color: #fff;
    border: 2px solid #000;
    cursor: pointer;
}

.image-preview-actions:hover {
    opacity: 1;
}