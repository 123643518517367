.letter-row{
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: -0.5px;
    height: 50px;
    &.second{
        padding: 0 10px;
    }
    &.third{
        padding: 0 20px;
    }

    .letter{
        // margin-right: -1px;
        font-size: 28px;
        display: flex;
        margin: 2.5px 1.5px;
        align-items: center;
        justify-content: center;
        width: 100%;
        border: 1px solid black;
        border-radius: 5px;
        user-select: none;
        &:hover{
            filter: brightness(85%);
            cursor: pointer;
        }
        &:active{
            filter: brightness(75%);
            cursor: pointer;
        }

        &.isButtons3D{
            box-shadow: 0 3px #999;
            &:active {
                box-shadow: 0 1px #666;
                transform: translateY(2px);
              }
        }
    }
}