.create-trivia-question {
  justify-content: space-between;
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  @media (min-width: 480px) {
    padding: 0 15px;

  }
}
.create-trivia-section-input {
  max-width: calc(100% - 70px);
  flex: 1;
  //max-width: 210px;
  padding: 0 10px;
  margin: 0;
  @media (min-width: 640px) {
    max-width:100%;
  }
}
.create-trivia-option {
  margin-bottom: 20px;
  @media (min-width: 480px) {
    padding: 0 15px;
  }
}