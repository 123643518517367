@import "./variables/variables-gutters.scss";
@import "./variables/variables-colors.scss";
@import "./variables/variables-font-size.scss";
@import "./variables/keyframes.scss";
@import "./variables/variables-viewports.scss";

.single-adventure {

}

.single-adventure-container {
  z-index: 1;
}

.single-adventure-container-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 400px;
}

.create-adventure-form-wrapper {
  margin: 0 auto;
  h2 {
    margin: 0;
    font-size: $font-size-xl;
    @media (min-width: $viewport-sm) {
      font-size: $font-size-xxl;
    }
  }
  p {
    font-size: $font-size-md;
  }
}

.create-adventure-form-description {
 }

.create-adventure-form {
  //max-width: 50rem;
  margin: 0 auto 30px;
}

.create-adventure-form-submit {
  color: #fff;
  margin: 20px auto 0;
  width: 26rem;
}
.screen-adventure-login {
  min-height: 0;
  .login-form-wrapper {
    padding: 0;
    margin:0;
  }
}

.single-adventure-similar {
  //max-width: 1400px;
  //margin: 0 auto;
  //padding: 0;
}