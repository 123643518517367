
.single-blog {
  p {
    color: #000;
    .theme-dark & {
      color: #fff;
    }
  }
}

.blogs-gallery-item {
  p, span {
    background-color: transparent !important;
  }
}

.blogs-gallery-item {
  p, span {
    color: #000 !important;
    .theme-dark & {
      color: #fff !important;
    }
  }
}
