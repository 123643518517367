@import "./variables/variables-gutters.scss";
@import "./variables/variables-colors.scss";
@import "./variables/variables-font-size.scss";
@import "./variables/keyframes.scss";
@import "./variables/variables-viewports.scss";

.error-404-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .error-404-data-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .error-404-text-container {
       //width: 50%;
      .error-404-text-header{
        font-size: 6em;
      }
      .error-404-back-link {
        color: $color-blue;
      }
    }
    .error-404-image {
      object-fit: contain;
    }
  }
  .error-404-suggestions{
    //width: 80%;
  }
}