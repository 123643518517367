@import "./variables/variables-gutters.scss";
@import "./variables/variables-colors.scss";
@import "./variables/variables-font-size.scss";
@import "./variables/keyframes.scss";
@import "./variables/variables-viewports.scss";

.dashboard {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  &.isEvening {
    background-color: #310e68;
    background-image: linear-gradient(316deg, #310e68 0%, #5f0f40 74%);
  }
  &.isNight {
    background-color: #111;
  }
}

.dashboard-items {

}

.dashboard-greeting {
  display: block;
  text-align: center;
  .isEvening &, .isNight & {
    color: #f2f2f2;
  }
}

.dashboard-question {
  text-align: center;
  font-size: 30px;
  .isEvening &, .isNight & {
    color: #f2f2f2;
  }
}

.dashboard-title {
  text-align: center;
  font-weight: bold;
  margin-bottom: 0;
  .isEvening &, .isNight & {
    color: #f2f2f2;
  }
}

.dashboard-menu {
  display: flex;
  flex-wrap: wrap;
}

.dashboard-menu-item {
  font-size: 30px;
  padding: 20px;
  
}
.dashboard-menu-item-link{
  text-decoration: none;
}

.dashboard-menu-item-container {
  background-color: #5388ff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  //height: 100px;
  border-radius: 10px;
  color: #fff;
  p {
    font-size: $font-size-md;
    margin: 10px 0;
  }
  &:hover {
    text-decoration: underline;
  }
}

.daily-song {
  h4, p, a {
    text-align: center;
    .isEvening &, .isNight & {
      color: #f2f2f2;
    }
  }
  a {
    font-size: 2.8rem;
    text-decoration: underline;
  }
}