.tool-choose-item {
  width: 100%;
  &.grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}

.tool-choose-item-section {
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px 0;
  margin: 10px 0;
  cursor: pointer;
  .grid & {
    padding: 10px;
    margin: 0;
  }
}

.tool-choose-item-image {
  position: absolute;
  right: 10px;
}

.tool-choose-item-text {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  flex: 1;
  font-size: 20px;
  p {
    text-align: center;
    margin: 0;
  }
  .grid & {
    box-shadow: 0 1px 2px #000;
  }
  .tool-choose-item-section.active & {
    background-color: #6c757d;
    color: #fff;
  }
}

.screen-tool-78 {
  .tool-text-before {
    text-align: center;
  }
}