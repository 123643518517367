.main-adventure-thumbnail {
  padding-top:20px;
  padding-bottom:20px;
  min-height: 1px;
  position: relative;
  right: auto;
  left: auto;
}
.slider_arrow-left {
  left: 10px;
  @media (min-width: 640px) {
    left: 20px;
  }
  @media (min-width: 992px) {
    left: 30px;
  }
  @media (min-width: 1200px) {
    left: 40px;
  }
}
.slider_arrow-right {
  right: 10px;
  @media (min-width: 640px) {
    right: 20px;
  }
  @media (min-width: 992px) {
    right: 30px;
  }
  @media (min-width: 1200px) {
    right: 40px;
  }
}
