.navigation-group-item {
    font-size: 14px;
    cursor: pointer;
}
.navigation-group-item.active {
    font-weight: bold;
}
.navigation-group-item:hover {
    text-decoration: underline;
}


.navigation-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 5px;
    background-color: #00a2ff;
    color: #fff;
}
.navigation-group-nav {
    cursor: pointer;
}