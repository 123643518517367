.cutting-wire-images {
  display: flex;
}
.cutting-wire-image {
  padding: 10px;
}

.cutting-wires-items {
  display: flex;
  flex-wrap: wrap;
}