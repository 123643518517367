@import "./variables/variables-gutters.scss";
@import "./variables/variables-colors.scss";
@import "./variables/variables-font-size.scss";
@import "./variables/keyframes.scss";
@import "./variables/variables-viewports.scss";

.adventure-type-card{
  text-decoration: none;
}

.adventure-type-card-price {
  text-decoration: none;
  span {
    font-size: $font-size-md;
    font-weight: bold;

    //font-family: "Fedra Sans Hebrew Medium";
    @media (min-width: $viewport-lg) {
      font-size: $font-size-lg;
    }
  }
}