.language-selector {
  border: 0;
  margin-right: 10px;
  cursor: pointer;
  display: flex;
  outline: none;

  .languages-wrapper {
    top: 50px;
    position: relative;
    border-radius: 5px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, .7);
    z-index: 1;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    width: 115px;
    padding: 5px 0;
    transform: translateX(-47px);
    .lang-he & {
      transform: translateX(47px);
    }

    &:before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      top: -5px;
      transform: rotate(45deg);
      box-shadow: -1px -1px 1px rgba(0, 0, 0, .5);
      z-index: 0;
      background-color: #fff;
    }

    & > div {
      width: 100%;
      position: relative;
      z-index: 1;
      text-align: center;
      background-color: #fff;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.language-selector-item {

}