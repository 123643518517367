.burger-menu {
  position: fixed;
  top: 10px;
  left: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1000000;
  border-radius: 20px;
  font-size: 26px;
  color: #fff;
  cursor: pointer;
  background-color: #ff00bb;
  transition: all .5s;
  padding:7px;
  overflow: hidden;
  &.active {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}
.burger-menu-icon {
  position: absolute;
  top: 7px;
  left:7px;
}

.mobile-menu {
  display: flex;
  flex-direction: column;
}
.mobile-menu-item {
  padding: 10px 0;
  font-size:40px;
  font-weight: bold;
  a {
    color: #333333;
    &:hover {
      text-decoration: underline;
    }
  }
}