@import "./variables/variables-font-size.scss";

.card-title {
  margin-bottom:0;
  font-size: $font-size-xl;
}
.card-description {
  font-size: $font-size-base;
}
.card-image {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 20px 0;
}