.peek-lock-container{
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    user-select: none;
    &::after{
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background: url("../images/lock-background.png") center center no-repeat;
        filter: blur(20px);
    }

    .lock-image{
        user-select: none;
        position: absolute;
        background: url("../images/lock-background.png") center center no-repeat;
        background-size: cover;
        object-fit: fill;
        height: 100%;
        width: 100%;
        z-index: 5;
        cursor: grabbing;
    }

    .inside-image{
        user-select: none;
        //position: absolute;
        object-fit: contain;
        height: 100%;
        width: 100%;
        z-index: 1;
    }
}