$borderColor: #7b7a7d;

.rdw-dropdown-carettoopen {
  right: auto;
  left: 5px;
}
.rdw-editor-toolbar {

}
.rdw-editor-main {
  padding: 6px 5px 0;
  border-radius: 2px;
  border: 1px solid $borderColor;
}
.demo-editor {
  font-size: 18px;
  min-height: 150px;
}