@import "./variables/variables-gutters.scss";
@import "./variables/variables-colors.scss";
@import "./variables/variables-font-size.scss";
@import "./variables/keyframes.scss";
@import "./variables/variables-viewports.scss";

.blog-edit-container{


  .blog-edit-back-button{
    height: 50px;
    width: 150px;
    background-color: $color-select;
    align-self: flex-end;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2em;
    border-radius: 50px;
    border: 1px solid black;
    color: white;
    user-select: none;
    cursor: pointer;
  }

  .submit-blog-edit-button {
    width: 150px;
    height: 40px;
    border-radius: 10px;

    &.active {
      cursor: pointer;
      background-color: $color-select;
      &:hover{
        background-color: rgba($color-select, 0.8);

      }
    }
  }
}