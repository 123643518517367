@import "./variables/variables-gutters.scss";
@import "./variables/variables-colors.scss";
@import "./variables/variables-font-size.scss";
@import "./variables/keyframes.scss";
@import "./variables/variables-viewports.scss";

.businesses {
  position: relative;
}
.businesses-description {
  display: flex;
  flex-direction: column;
  p {
    margin: 0;
  }
}
.businesses-color-image {
  position: relative;

  left: 0;
  //display: none;
  width: 45rem;
  max-width: 100%;
  @media (min-width: $viewport-md) {
    display: block;
    top: -70px;
  }
  @media (min-width: $viewport-lg) {
    width: 45rem;
  }
}
.businesses-paragraph {
  @media (min-width: $viewport-md) {
    &:first-child {
      align-self: flex-start;
      max-widtH: 45rem;
    }
    &:nth-child(2) {
      align-self: center;
      max-width: 61.4rem;
    }
    &:nth-child(3) {
      align-self: flex-end;
      max-width: 61.4rem;
    }
  }
  p {
    align-items: center;
    font-size: $font-size-md;
    line-height: $font-size-md + 1;
  }
}