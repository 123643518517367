$gutter-xxxs: .25rem;
$gutter-xxs: .5rem;
$gutter-xs: 1rem;
$gutter-sm: 1.5rem;
$gutter-md: 3rem;
$gutter-lg: 6rem;
$gutter-xl: 9rem;
$gutter-xxl: 12rem;
$gutter-xxxl: 15rem;
//
// $gutter: (
//        xxxs: .25rem,
//        xxs: .5rem,
//        xs: 1rem,
//        sm: 1.5rem,
//        md: 3rem,
//        lg: 6rem,
//        xl: 9rem,
//        xxl: 12rem,
//        xxxl: 15rem
//) !default;
//
//@mixin padding($size, $dir: 0) {
//  $value: map-get($gutter, $size);
//  @if ($dir == 0) {
//    padding: $value;
//  } @else {
//    @if ($dir == x or $dir == l) {
//      padding-left: $value;
//    }
//    @if ($dir == x or $dir == r) {
//      padding-right: $value;
//    }
//    @if ($dir == y or $dir == t) {
//      padding-top: $value;
//    }
//    @if ($dir == y or $dir == b) {
//      padding-bottom: $value;
//    }
//  }
//}
//
//@mixin margin($size, $dir: 0) {
//  $value: map-get($gutter, $size);
//  @if ($dir == 0) {
//    margin: $value;
//  } @else {
//    @if ($dir == x or $dir == l) {
//      margin-left: $value;
//    }
//    @if ($dir == x or $dir == r) {
//      margin-right: $value;
//    }
//    @if ($dir == y or $dir == t) {
//      margin-top: $value;
//    }
//    @if ($dir == y or $dir == b) {
//      margin-bottom: $value;
//    }
//  }
//}