.switches-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  .frame-14769 & {
    flex-wrap: nowrap;
    min-width: 43rem;
    .switch-single-title {
      font-size: 1.7rem;
    }
  }
  &.row {
    flex-direction: row;
    justify-content: space-evenly;
  }
  &.column {
    flex-direction: column;
  }
}