$white: #ffffff !default;
$gray-100: #eeeeee !default;
$gray-200: #dddddd !default;
$gray-300: #bbbbbb !default;
$gray-400: #888888 !default;
$gray-500: #777777 !default;
$gray-600: #454545 !default;
$black: #000 !default;

$light: $gray-100 !default;
$dark: $gray-600 !default;

$color-white: $white;
$color-black: $black;
$color-blue: #00ADDC;
$color-green: #26B48C;
$color-pink: #FF5FA0;
$color-red: #7d2115;
$color-yellow: #FFD23B;
$color-light-pink: #FEAEBB;
$color-light-blue: #8FD5FF;
$color-lilach: #B1A2FF;
$color-orange: #FF8E6C;

$color-info: $color-blue;
$color-select: $color-green;
$color-action: $color-pink;
$color-warning: $color-red;
$color-disabled: $gray-500;