.translator-preview {
  display: flex;
  flex-wrap: wrap;
  padding: 30px;
  border: 2px dashed #000;
}
.translator-preview-item {
  width: 50px;
  display: flex;
  &.special {
    width: auto;
    align-items: flex-end;
  }
  &.space {

  }
}

.translator-preview-image {
  width: 100%;
}
.translator-preview-special {
  font-size: 40px;
  padding: 0 10px;
}
