.admin-conversation {
  background-color: #fff;
  padding:15px;
}
.admin-conversation-placeholder {
  width: 100%;
  height: 40px;
  border: 1px solid #454545;
  cursor: text;
}
.admin-conversation-comment {
  display: flex;
  margin: 30px 0;
  h6 {font-size: 16px; margin:0}
  p {
    margin:0;
  }
}