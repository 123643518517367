@import "./variables/variables-gutters.scss";
@import "./variables/variables-colors.scss";
@import "./variables/variables-font-size.scss";
@import "./variables/keyframes.scss";
@import "./variables/variables-viewports.scss";
.adventures-for-business-card {
  //display: flex;
  max-width: 800px;
  margin: 0 auto;
  h3 {
    margin-bottom: 10px;
  }
  button {
    display: block;
    margin: 25px auto;
    font-size: $font-size-md;
  }
}