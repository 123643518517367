.coder-create-preview-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
  background-color: #fff;
}
.coder-create-preview-button {

}

.coder-style-section {
  margin-bottom: 15px;
}
.coder-icons {
  margin-bottom: 10px;
}
.coder-colors {
  display: flex;
  flex-wrap: wrap;
}

.coder-icons {

}
.create-coder-icons-preview {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  justify-content: center;
  max-width: 250px;
.images-container {
  justify-content: center;
}
  .images-trigger {
    border: 0 !important;
  }
}
.create-coder-icon {
  width: 33.33%;
}