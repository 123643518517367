
.adventure-type-game-images {
  display: flex;
  margin: 30px 0;
  width: 100%;

  .preview {
    margin-left: 20px;
    height: 100px;

    p {
      font-size: 15px;
    }

  }
}