.table-items-header {
  margin-bottom: 15px
}

.table-items-add {
  white-space: nowrap;
  border-radius: 1.4px;
  background-color: #5388ff;
  color: #fff;
  .table-items-header-search & {
    padding-left: 60px;
    padding-right: 60px;
    background-color: #808080;
  }
}

.table-items-header-search {
  display: flex;
  align-items: flex-start;
  width: 100%;

}

.table-filter-menu {
  position: absolute;
  top: 30px;
  right: 20px;
  z-index: 100;
  background-color: #fff;
  padding: 5px 0;
}
.table-filter-menu-item {
  label {
    font-size: 16px;
    font-weight: normal;
    display: flex;
    cursor: pointer;
    align-items: center;
    white-space: nowrap;
    padding:  5px 15px;
  }
  &:hover {
    background-color: #E0E0E0;
  }
}
.sort-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 5px;
  padding:0 5px;
}
.toggle-menu-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background-color: #b3b3b3;
  text-align: center;
  width:11px;
  height: 11px;
  cursor: pointer;
  margin-left: 5px;

  &.menuActive {
    background-color: #4d4d4d;
  }
  &.filterActive {
    background-color: #5388ff;
    width:13px;
    height: 13px;
  }
  img {
  }
}