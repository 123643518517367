.filter-indicators {
  display: flex;
  align-items: center;
  margin-top: 15px;

  label {
    font-weight: bold;
    font-size: 14px;
    color: #4d4d4d;
  }
}

.filter-indicators-item {
  padding: 0 10px;
  color: #5388ff;;
  font-size: 16px;
  span {
    padding: 0 5px;
    cursor: pointer;
  }

  img {
    cursor: pointer;
    & + span {
      cursor: auto;
    }
  }
}