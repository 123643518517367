.field-options-container {
  display: flex;
  flex-wrap: wrap;
}

.field-options-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #7b7a7d;
  background-color: #fff;
  cursor: pointer;
  text-align: center;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .lang-rtl & {
    margin-right: 0;
    margin-left: 10px;
  }

  &.active {
    color: #fff;
    background-color: #7b7a7d;
    text-decoration: underline;
  }
}