.tool-content {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  padding-top: 20px;
  position: relative;
  .adventure-5728 & {
    max-width: 800px;
  }
  .frame-4170 & {
    padding:0;
  }

  &.tool-content-grid {
    display: flex;
    flex-wrap: wrap;
  }

  .before {
    position: absolute;
    top: -25px;
    right: -.1rem;
    padding: 3px 20px 0 40px;
    font-size: 18px;
    background-color: #d3ae3d;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-top: 1px solid #000;
    border-right: 1px solid #000;
    border-left: 1px solid #000;
  }
  p.tool-content-text {
    font-size: 1.8rem;
    margin-top:10px;
    white-space: pre-wrap;
    padding: 10px 20px;
    color:#000;
    p {
      margin-top: 0;
      margin-bottom: 0;
    }
    .adventure-type-324 &,
    .adventure-type-330 &,
    .adventure-type-332 & {
      font-size: 24px;
      color:#000
    }
  }
  &.tool-content-tabs {
    p.tool-content-text {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.tool-content-item {
  .tool-content-grid & {
    width: 50%;
  }

}