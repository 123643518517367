@import "./variables/variables-gutters.scss";
@import "./variables/variables-colors.scss";
@import "./variables/variables-font-size.scss";
@import "./variables/keyframes.scss";
@import "./variables/variables-viewports.scss";

.nav-item {
  display: flex;
  margin-right: 0;
  padding: 0 1.2rem;
  cursor: pointer;
  font-size: $font-size-sm;
  font-weight: bold;
  //font-family: $font-family-base;
  //color: #000;
  text-transform: uppercase;
  &:hover {
    * {
      font-weight: bold;
    }
  }
}