.phone-call {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  background: linear-gradient(to bottom left, #0e3441, #0c1323);
}

.phone-call-container {
  width: 100%;
}

.phone-call-panel {
  position: relative;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between
}

.phone-call-header {
  height: auto;
}

.phone-call-top-icon {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  background: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto
}

.phone-call-main-details {
  margin-top: 2rem;
  color: white;
  text-align: center
}

.phone-call-name {
  font-weight: bold;
  margin-top: 0
}

.phone-call-number {
  font-weight: normal
}

.phone-call-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
}

.phone-call-imageType {
  display: flex;
  height: 100%;
  align-items: center
}

.phone-call-videoType {
  display: flex;
  height: 100vh;
  justify-content: center
}

.phone-call-audio {
  position: absolute;
  opacity: 0
}

.phone-call-close {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  bottom: 40px;
  button {
    border-radius: 50%;
    border: 0;
    height: 65px;
    width: 65px;
    background: #f24139;
    cursor: pointer;
  }
}

.phone-call-icons {
  display: flex;
  flex-wrap: wrap;
  border: 0;
  padding: 50px 0;
}

.phone-call-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33.33%;
  padding: 15px;
  color: white;
  font-size: 3rem;
  cursor: pointer;
  button {
    background-color: transparent;
    border: 0;
    outline: none;
  }
  &.disable {
    opacity: .4;
    cursor: default;
  }
}