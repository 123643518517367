.clues-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.clues-add-clue {
  background-color: transparent;
  display: flex;
  align-items: center;
  color:#000;
  span {
    padding-right: 5px;
  }
}
.single-clue {
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 10px;
  background-color: #efeeee;
}
.single-clue-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0 10px;
}

.single-clue-body {
  background-color: #f5f5f5;
  border-top: 1px solid #000;
  padding: 10px;
}