.coder-numbers-row {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  margin: 5px 0;
}

.coder-number {
  padding: 15px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid black;
  user-select: none;
  font-size: 18px;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background-color: #44435e;
  border: 2px solid #fff;
  color: #fff;
  font-size: 27px;
  &:hover {
    filter: brightness(85%);
    cursor: pointer;
  }

  &:active {
    filter: brightness(75%);
    cursor: pointer;
  }

  .disabled & {
    filter: initial;
    cursor: default;
  }
  &.isButtons3D {
    box-shadow: 0 3px #999;

    &:active {
      box-shadow: 0 1px #666;
      transform: translateY(2px);
    }
  }
}

.coder-color-value{
  height: 10px;
  width: 10px;
}