@import "./variables/variables-gutters.scss";
@import "./variables/variables-colors.scss";
@import "./variables/variables-font-size.scss";
@import "./variables/keyframes.scss";
@import "./variables/variables-viewports.scss";

$viewport-min:  900px;//$viewport-sm;
$viewport-max:  $viewport-xl;

.app-header {
  z-index: 500;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 1rem 1.5rem;
  position: fixed;
  width: 100%;
  .theme-dark & {
    background-color: #000;
  }
  .scrolling & {
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
  }
}

.app-header-item {
  font-size: $font-size-sm;
  display: flex;
  align-items: center;
  width: auto;
  @media (min-width: $viewport-min) and (max-width: $viewport-max) {
    width: auto;
  }
  @media (min-width: $viewport-max) {
    width: auto;
  }
}

.app-header-left {

  .app-header-min-nav {
    display: none;
    padding-right: $gutter-sm;
    @media (min-width: $viewport-min) {
      display: flex;
    }
  }
}

.app-header-right {
  display: flex;
  justify-content: flex-end;
}

.app-header-center {
  width: 120px;
  width: 100%;
  display: flex;
  justify-content: center;
  @media (min-width: $viewport-min) and (max-width: $viewport-max) {
    width: 100%;
  }
  @media (min-width: $viewport-max) {
    width: 120px;
  }
}

.app-header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  margin: 0 -20px;
}

.header-create-adventure {
  margin-left: 15px;
  .lang-rtl & {
    margin-right: 15px;
    margin-left: 0;
  }
}

.create-adventure-button {
  //width:144px;
  //height: 30px;
  //padding:0;
  cursor: pointer;
  //border-radius: 0;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: #fff;
  }
}

.app-header-mobile-trigger {
  margin-right: 15px;
  @media (min-width: $viewport-min) {
    display: none;
  }
}

.mobile-navigation {
  position: fixed;
  transition: transform .3s;
  right: 0;
  left: 0;
  top: 0;
  z-index: 101;
  width: 100vw;
  height: 100%;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transform: translateX(110vw);
  .theme-dark & {
    background-color: #fff;
  }
  &.active {
    transform: translateX(0);
  }
  .nav-item {
    text-align: center;
    width: 100%;
    justify-content: center;
    padding: .5rem 0;
    color: #fff;
    .theme-dark & {
      color: #000;
    }
    a, button {
      color: #fff;
      font-weight: bold;
      .theme-dark & {
        color: #000;
      }
    }
  }
}

.app-header-side-menu {
  position: absolute;
  right: 0;
  top: 75px;
  z-index: 1000;
  background-color: #fff;
  box-shadow: .0rem .0rem .5rem 0 rgba(0, 0, 0, .1);
  display: flex;
  flex-direction: column;
  opacity: 0;
  transition: transform .3s, opacity .3s;
  transform: translateX(500px);
  .theme-dark & {
    background-color: #000;
  }
  .lang-he & {
    transform: translateX(-650px);
    right: auto;
    left: 0;
  }
  &.active {
    transform: translateX(0);
    opacity: 1;
  }
}

.app-header-notifications {
  position: absolute;
}