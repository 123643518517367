.tips {
  margin: 20px 0;
}

.tips-header {
  display: flex;
  justify-content: space-between;
  background-color: #efeeee;
  padding: 15px 10px;
  cursor: pointer;
}

.tips-body {
  background-color: #fff;
  padding: 10px;
  overflow: hidden;
}

.tips-field {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
}

.tips-field-index {
  padding: 0 10px;
  font-size: 14px;
}

.tips-add {
  display: flex;
  align-items: center;
  background-color: transparent;
  border:0;
  cursor: pointer;
  font-size: 16px;
  outline: 0;
  span {
    padding: 0 5px;
  }
}

.tips-remove {
  cursor: pointer;
  margin-right: 10px;
}