 .single-button-input-container{
    height: 80px;
    margin: 10px;
    width: 25%;
 }
 .switches-all-section{
    position: relative;
     margin: -22px -22px 20px -22px;
     padding-top: 20px;
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     border-bottom: 5px solid transparent;
     border-image: linear-gradient(to bottom right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%);
     border-image-slice: 1;
    }
    
 .switches-section-title{
     position: absolute;
     top: 0;
     right: 45%;
     text-align: center;
     font-weight: bold;
     border-bottom: black 1px solid;

 }

 .create-rotaries-item{
   input::-webkit-outer-spin-button,
   input::-webkit-inner-spin-button {
     /* display: none; <- Crashes Chrome on hover */
     -webkit-appearance: none;
     margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
   }

   input[type=number] {
     -moz-appearance:textfield; /* Firefox */
   }
 }

 .create-tool-table-wrapper {
   max-width: 100%;
   overflow: auto;
   &::-webkit-scrollbar {
     // display: none;
     background-color: rgba(0,0,0,0.3);
     height: 5px
   }
   &::-webkit-scrollbar-track {
     width: 5px
   }
   &::-webkit-scrollbar-thumb {
     background-color: #E0E0E0;
     border-radius: 2px;
     width: 5px
   }
 }

 .create-tool-rotaries-ui {
   font-size: 18px;
   padding: 5px 10px;
   background-color: #7b7a7d;
   color: #fff;
   cursor: pointer;
   margin-bottom: 15px;
 }