$sun-size: 150px;
$sun-color: orange;
$sun-top: 100px;
$sun-right: 250px;
$sun-beam-height: 5px;
$sun-beam-width: $sun-size * 1.4;
$sun-rotate-speed: 22s;

.sun {
  width: $sun-size;
  height: $sun-size;

  background-color: $sun-color;
  border-radius: 50%;
  position: absolute;
  top: $sun-top;
  right: $sun-right;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: rotate-the-sun $sun-rotate-speed linear infinite;
  .sun_beam {
    width: $sun-beam-width;
    height: $sun-beam-height;
    background-color: $sun-color;
    position: absolute;
    &:nth-child(1) {
      transform: rotate(45deg);
    }
    &:nth-child(2) {
      transform: rotate(90deg);
    }
    &:nth-child(3) {
      transform: rotate(135deg);
    }
  }
}

@keyframes rotate-the-sun {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}