.google-maps-container{
    height: calc(100vh - 130px);
    width: 100%;
    position: relative;

    .google-maps-search{
        height: 40px;
        width: 250px;
        position: absolute;
        right: 10px;
        top: 50px;
        border-radius: 8px;
        border: 1px solid grey;
        padding-right: 15px;

        &:focus{
            outline: none;
        }

    }
}