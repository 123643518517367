@import "./variables/variables-gutters.scss";
@import "./variables/variables-colors.scss";
@import "./variables/variables-font-size.scss";
@import "./variables/keyframes.scss";
@import "./variables/variables-viewports.scss";

.single-frame-additional-data {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.additional-data-header {
  background-color: #E0E0E0;
  padding: 10px 20px;
  cursor: pointer;

  h4 {
    font-size: 22px;
    font-weight: normal;
    margin: 0 0 20px;
  }
}

.additional-data-body {
  flex: 1;
  max-height: 0;
  overflow: auto;
  margin-top: 0;
  transition: max-height .4s, margin-top .4s;
  &::-webkit-scrollbar {
    display: none;
  }
  label {
    font-size: $font-size-xs;
    font-weight: normal;
  }
  &.active {
    margin-top: 15px;
    max-height: 700px;
  }
}

.additional-data-body-content {
  border-radius: 5px;
  padding: 20px 20px;
  background-color: #f8f7f7;
  margin-bottom: 20px;
}