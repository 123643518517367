
// *** FADE *** //
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}


// *** SCALE*** //
@keyframes scale {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
@keyframes scale-close {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}


// *** SLIDE *** //
@keyframes slide-b {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes slide-b-close {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}
@keyframes slide-l {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slide-l-close {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
@keyframes slide-r {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slide-r-close {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}
@keyframes slide-t {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes slide-t-close {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}