.contact-list-dropdown-item {
    cursor: pointer;
    padding: 5px 20px;
}
.contact-list-dropdown-item:hover {
    background-color: #00d99d;

}
.contact-list-dropdown-item p {
    font-size: 18px;
    margin: 0;
}
.contact-list-dropdown-item:hover p {
    color:#fff !important;
}
.css-16c1so0-Control:hover {
    background-color: #fff;
}