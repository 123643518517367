.maze-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  .maze-image{
    height: 43vh;
    width: 100%;
    object-fit: contain;
  }

  .arrows-wrapper{
    align-self: center;
    height: 25vh;
    width: 25vh;
    position: relative;

    .arrow{
      $arrow-size:8vh;
      position: absolute;
      height: $arrow-size;
      width: $arrow-size;
      object-fit: contain;
      filter: drop-shadow(4px 5px 5px #222);
      &:hover{
        cursor: pointer;
        filter: brightness(120%) drop-shadow(4px 5px 5px #222);;
      }
      &:active{
        cursor: pointer;
        filter: brightness(140%) drop-shadow(4px 5px 5px #222);;
      }
      &.disabled{
        filter: grayscale(100);
        cursor: context-menu;
      }

      &.forward{
        top: 0;
        right: calc(50% - (#{$arrow-size} / 2));
      }
      &.right{
        top: calc(50% - (#{$arrow-size} / 2));
        right: 0;
      }
      &.left{
        top: calc(50% - (#{$arrow-size} / 2));
        left: 0;
      }
    }
    .text{
      position: absolute;
      text-align: center;
      font-size: 20px;
      height: 23px;
      width: 70px;

      &.forward{
        top: -30px;
        right: calc(50% - (70px/ 2));
      }
      &.right{
        top: calc(50% - (20px / 2));
        right: -70px;
      }
      &.left{
        top: calc(50% - (20px / 2));
        left: -70px;
      }
    }
  }
}
.maze-arrows {
  background-color: #d2d2d8;
  display: flex;
  justify-content: center;
  align-items: center;padding: 50px 10px;
}