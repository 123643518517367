.navigate-items {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
}

.navigate-items-numbers {
  display: flex;
  align-items: center;
}

.navigate-items-number {
  font-size: 18px;
  padding: 0 8px;
  text-align: center;
  color: #a1a1a1;
  cursor: pointer;
  &.active {
    font-weight: bold;
    color: #666;
  }
}

.navigate-items-nav {
  font-size: 18px;
  padding: 0 20px;
  cursor: pointer;
  color: #a1a1a1;
  opacity: 1;
  &.disable {
    opacity: .5;
    cursor: default;
  }
}