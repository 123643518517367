.create-space-adventure {

  &.en {
    p, input, label {
      direction: ltr;
    }
  }
}

.choose-language {
  font-size: 20px;
  color: #fff;
}

.language-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: inherit;

  button {
    border: 0;
    cursor: pointer;
    border-radius: 7px;
    padding: 4px 20px;
    color: #fff;
    font-size: 18px;
    margin: 0 10px;
    outline: 0;
    &.en {
      background-color: #ff00a2;
      .he & {
        &:hover {
          text-decoration: underline;
        }
      }
    }

    &.he {
      background-color: #00a2ff;
      .en & {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}