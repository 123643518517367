$breakpoint: 768px;

.single-adventure {
  width: 100%;
  position: relative;

  .route-admin & {
    background-color: #fff;
  }
  .tabs {
    flex-direction: column;
    @media (min-width: $breakpoint) {
      flex-direction: row;
    }
  }

  .tabs-labels {
    flex-direction: row;
    padding: 0;
    @media (min-width: $breakpoint) {
      flex-direction: column;
      align-items: center ;
      padding-top: 30px;
      padding-left: 20px;
    }
  }
  .tabs-content {
    padding: 0;
    @media (min-width: $breakpoint) {
      padding-right: 20px;
    }
  }
  .table-inner-menu {
    left: 10px;
  }
}

.single-adventure-type-name {
  font-size: 20px;
  margin: 0;
  padding: 0 20px;
}

.single-adventure-type-label {
  width: 70px;
    height: 70px;
  span {
    display: block;
    font-size: 14px;
  }
}

.single-adventure-type-name-label {
  font-size: 18px;
}

