@import "./variables/variables-gutters.scss";
@import "./variables/variables-colors.scss";
@import "./variables/variables-font-size.scss";
@import "./variables/keyframes.scss";
@import "./variables/variables-viewports.scss";

  .single-adventure-details-item {
    display: flex;
    flex-wrap: wrap;
    flex: 1;

    @media (min-width: 480px) {
      flex-wrap: nowrap;
    }
  }

  .single-adventure-title {
    text-align: left;
    font-size: 5.2rem;
    line-height: 5.5rem;
    margin: 0;
    color: #00addc;

    @media (min-width: 480px) {
      font-size: 7.2rem;
    }
    @media (min-width: 1200px) {
      margin: 0 15px 0 0;
      //line-height: 3rem;
      .lang-rtl & {
        margin: 0 0 0 15px;
      }
    }
    .lang-rtl & {
      text-align: right;
    }
  }
  .single-adventure-description {
    white-space: pre-line;
    text-align:  left;
    font-size: 20px;
    margin-top: 50px;
    .lang-rtl & {
      text-align:  right;
    }
    @media (min-width: 1200px) {
      padding-right: 160px;
      .lang-rtl & {
        padding-right: 0;
        padding-left: 160px;
      }
    }
  }
  .single-adventure-price {
    font-size: $font-size-xl;
    font-weight: bold;
    margin-bottom: 20px;
    span {
      padding-right: 6px;
      font-size: 3.2rem;
    }
  }

  .single-adventure-detail {
    display: flex;
    align-items: center;
    margin-right: $gutter-md;
    overflow: visible;
    .lang-rtl & {
      margin-right: 0;
      margin-left: $gutter-md;
    }
    span {
      font-size: 2.2rem;
      white-space: nowrap;
      font-weight: bold;
    }
  }

  .single-adventure-details-icon {
    display: flex;
    align-items: center;
    margin-right: $gutter-sm;
    overflow: visible;
    .lang-rtl & {
      margin-right: 0;
      margin-left: $gutter-sm;
    }
  }