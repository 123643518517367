

@import "./variables/variables-gutters.scss";
@import "./variables/variables-colors.scss";
@import "./variables/variables-font-size.scss";
@import "./variables/keyframes.scss";
@import "./variables/variables-viewports.scss";

$breakpoint: 768px;
.review-container-height{
  height: 190px;

  @media (min-width: $breakpoint) {
    padding: 50px 30px;
    height: 152px;
  }
}

.review-mocks {
  padding: 50px 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media (min-width: $breakpoint) {
    padding: 100px 0;
    background-size: contain;
  }
  .slider_content {
    background-color: #fff;
    .theme-dark & {
      background-color: #000;
    }
  }
  .review-image-container{
    width: 100px;
    @extend .review-container-height;
    object-fit: contain;
    background-color: white;
    position: relative;
    .theme-dark & {
      background-color: #000;
    }
    .review-image-wrapper{
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &.fadeOut{
        animation: fade-out 0.6s linear, slide-l-little 4.1s;
      }
      &.fadeIn{
        animation: fade-in 0.6s linear;
      }
    }
    .review-image{
      margin: 3%;
      height: 60px;
    }
    .review-image-name{
      margin: 0;
      width: 100%;
      text-align: center;
      font-weight: bold;
      .theme-dark & {
        color: #fff;
      }
    }
  }

  .review-mocks-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 5%;
    background-color: white;
    .theme-dark & {
      background-color: black;
    }
  }
}

.review-mocks-thumb {
  display: flex;
  flex-direction: column;
  align-items: center;
  h6 {
    margin: 10px 0 0;
  }
}

.review-mock {
  background-color: #fff;
  padding: 10px 30px;
  overflow: hidden;
  border-radius: 10px;
  .theme-dark & {
    background-color: #000;
  }
  @extend .review-container-height;
}

.review-mocks-description {
  display: flex;
  align-items: center;
  font-size: 21px;

  p {
    margin: 0;
    text-align: left;

    .lang-rtl & {
      text-align: right;
    }
  }
}