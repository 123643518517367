@import "./variables/variables-gutters.scss";
@import "./variables/variables-colors.scss";
@import "./variables/variables-font-size.scss";
@import "./variables/keyframes.scss";
@import "./variables/variables-viewports.scss";

.adventure-progress-message {
  h2 {
    font-size: $font-size-xl;
  }
  @media (min-width:$viewport-sm) {
    h2 {
      font-size: $font-size-xxl;
    }
  }
}