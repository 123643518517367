.single-riddle {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 30px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  .tabs .tabs-labels {
    flex-direction: row;
  }

  &::-webkit-scrollbar {

    background-color: rgba(0, 0, 0, 0.3);

    margin-top: 20px;
    width: 5px
  }

  &::-webkit-scrollbar-track {
    width: 5px
  }

  &::-webkit-scrollbar-thumb {
    background-color: #E0E0E0;
    border-radius: 2px;
    width: 5px
  }
}

.single-riddle-submit {
  background-color: #5388ff;
  color: #fff;
  width: 160px;
  display: block;
  margin: 0 auto;
}

.riddle-frames {
  > ul {
    padding-right: 0;
    display: flex;
    flex-direction: column;

    .sortable-item {
      margin-bottom: 15px;
    }
  }

}

.riddle-frames-add {
  width: 100px;
  margin: 10px auto;
}