@import "./variables/variables-gutters.scss";
@import "./variables/variables-colors.scss";
@import "./variables/variables-font-size.scss";
@import "./variables/keyframes.scss";
@import "./variables/variables-viewports.scss";


.tool-truth-or-lie {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  flex: 1;
}

.tool-truth-or-lie-container {
display: flex;
  flex-direction: column;
  justify-content: center;
  width: 500px;
}

.frame-14771 {

}