.riddle-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background-color: transparent;
  border-bottom: 0;
  padding: 0;
  cursor: pointer;
}

.riddle-header-right {
  display: flex;
  align-items: center;
}

.riddle-header-riddle-id {
  visibility: hidden;

  &.visible {
    visibility: visible;
    cursor: pointer;
  }
}
