@import "./variables/variables-gutters.scss";
@import "./variables/variables-colors.scss";
@import "./variables/variables-font-size.scss";
@import "./variables/keyframes.scss";
@import "./variables/variables-viewports.scss";

.category-description {
  .rdw-editor-main {
      background-color: $color-white;
  }
  .rdw-editor-toolbar {
    margin-bottom:0;
  }
}

.category-preview-tool {
  width: 100%;
  height: 100%;
  .single-frame-section {
    height: 100%;
    margin: 0;
  }
}