.tool-cutting-wires-items {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}
.tool-cutting-wires-items-title {
  text-align: center;
  font-size: 20px;
  margin-bottom: 0;
}
.tool-cutting-wires-item {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 10px;
  font-size: 15px;
  color: #fff;
  text-align: center;
  border: 2px solid #fff;
  line-height: 13px;
}
.tool-cutting-wires-image {
  width: 100%;
  img {
    display: none;
    &.active {
display: block;
    }
  }
}

.tool-cutting-wires-alert {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  h4 {
    width: 100%;
    text-align: center;
    font-size: 20px;
  }
  button {
    margin: 0 5px;
  }
}
.tool-cutting-wires-timer {
  width: 100px;
  height: 100px;
  margin: 10px auto;
  font-size: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  color: #fff;
}