@import "./variables/variables-gutters.scss";
@import "./variables/variables-colors.scss";
@import "./variables/variables-font-size.scss";
@import "./variables/keyframes.scss";
@import "./variables/variables-viewports.scss";

.subscribe {
  max-width: 61.8rem;
  margin: 0 auto;
  padding: 60px 40px 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  display: none;
  @media (min-width: $viewport-sm) {
    padding: 50px;
    display: block;
  }
}

.subscribe-description {
  font-size: $font-size-sm;
  padding: 0 20px 0 10px;
  margin: 0;
  @media (min-width: $viewport-sm) {
    font-size: 2.2rem;
    line-height: 3.2rem;
  }
}

.subscribe-envelopes {
  display: inline-block;
  width: 90px;
  @media (min-width: $viewport-sm) {
    width: auto;
  }
}