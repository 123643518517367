@import "./variables/variables-gutters.scss";
@import "./variables/variables-colors.scss";
@import "./variables/variables-font-size.scss";
@import "./variables/keyframes.scss";
@import "./variables/variables-viewports.scss";

.contact-us-container{
  .contact-form-wrapper{
    min-height: 400px;
    position: relative;
    z-index: 10;
    @media (min-width:  $viewport-md) {
      min-height: 350px;
    }
    .contact-form-container{
      position: absolute;
      padding: 25px 30px;
      border: solid 2px #000000;
      background-color: $color-white;
      z-index: 1;
      .input-container{
        height: 40px;
      }
    }
    .form-background{
      left: -30%;
      top: -19%;
      position: absolute;
      z-index: -1;
      height: 100%;
      transform: scale(0.9);
    }
    .form-subscribe{
      left: -5%;
      top: -18%;
      position: absolute;
      z-index: 2;
      transform: scale(0.8);
    }
  }
}
.further-contact-us-text-title {
  white-space: nowrap;
}