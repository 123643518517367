@import "./variables/variables-gutters.scss";
@import "./variables/variables-colors.scss";
@import "./variables/variables-font-size.scss";
@import "./variables/keyframes.scss";
@import "./variables/variables-viewports.scss";

.about-us {
  position: relative;
 }
.about-us-description {
  display: flex;
  flex-direction: column;
}
.about-us-color-image {
  position: absolute;
  top: 30px;
right: 0;
  transform: scaleX(-1);;
  display: none;
  width: 54rem;
  .lang-rtl & {
    right: auto;
    left: 0;
    transform: scaleX(1);
  }
  @media (min-width: $viewport-md) {
    display: block;
  }
  @media (min-width: $viewport-lg) {
    width: 60rem;
  }
}
.about-us-paragraph {
  @media (min-width: $viewport-md) {
    &:first-child {
      align-self: flex-start;
      max-widtH: 45rem;
    }
    &:nth-child(2) {
      align-self: center;
      max-width: 61.4rem;
    }
    &:nth-child(3) {
      align-self: flex-end;
      max-width: 61.4rem;
    }
  }
  p {
    align-items: center;
    font-size: $font-size-md;
    line-height: $font-size-md + 1;
  }
}