.create-contact-list {
  .preview-video {
    position: relative;
    .close-btn {
      position: absolute;
      widtH: 35px;
      height: 35px;
      left: 10px;
      top: 10px;
      background-color: #fff;
      border-radius: 50%;
      border: 0;
      z-index: 2;
      cursor: pointer;
      outline: none;
    }
  }
}