@import "./variables/variables-gutters.scss";
@import "./variables/variables-colors.scss";
@import "./variables/variables-font-size.scss";
@import "./variables/keyframes.scss";
@import "./variables/variables-viewports.scss";

.create-tool {
  display: none;
  padding: 0 $gutter-md $gutter-sm;
  .route-admin & {
    padding: 0 0 $gutter-sm;
  }
  .active & {
    display: block;
  }
}