.tool-audio-image {
   img {
    width: 100%;
    display: none;
    &.active {
      display: block;
    }
  }
}
.tool-audio-player {
  position: absolute;
  z-index: -1;
  visibility: hidden;
}