.adventure-type {
  width: 100%;
  position: relative;
  .adventure-type-sequence-title {
    display: none;
  }
}

.adventure-type-header {
  border-bottom: 1px solid #000;
  z-index: 100;
  h1 {
    font-weight: normal;
  }
}

.adventure-type-container {
  margin-top: 78px;
}

.adventure-type-main-details {
  position: relative;
  background-color: #ffff;
  overflow: visible;
}

.adventure-type-additional-details {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;

  .field {
    width: 25%;
    padding-left: 20px;
  }

  input {
    width: 180px;
    max-width: 100%
  }
}

.adventure-type-tab-item {
  background-color: #ffff;
  border-radius: 10px;
  position: relative;
  overflow: auto;
  padding: 20px;
  &.adventure-type-main-details {
    overflow: visible;
  }
  @media (min-width:768px) {
    padding: 20px;
    .single-adventure & {
      padding: 0;
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-track {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    display: none;
  }
  .single-adventure-form & {
    background-color: transparent;

  }

  &-flex {
    display: flex;
    justify-content: space-between;
  }

  .eye-button {
    background-color: #b3b3b3;
    border: 0;
    padding: 5px 10px;
    width: 47px;
    height: 30px;
    border-radius: 5px;
    text-align: center;
    cursor: auto;

    &.active {
      background-color: #5388ff;
      cursor: pointer;
    }
  }
}

.adventure-type-game {
  .eye-button {
    position: absolute;
    top: 10px;
    left: 10px;
  }

  h4 {
    margin-bottom: 5px;
  }
}
.adventure-type-subdomain {
  display: flex;
  align-items: center;
  margin-top: 20px;
  span {
    font-size: 20px;
    direction: ltr;
    padding-left: 5px;
  }
}
.adventure-type-main-details-form,
.adventure-type-site-details-container {
  max-width: 600px;
  margin-top: 15px;
}

.adventure-type-main-details-isActive {
  position: absolute;
  top: 30px;
  left: 30px;
}

.adventure-type-tab-container {
  max-width: 800px;
}

.adventure-type-site-details-images {
  display: flex;
  flex-wrap:wrap;
  margin: 30px 0;
  width: 100%;
}


.adventure-type-game-starter-btn {
  display: flex;
  margin: 30px 0;
  width: 100%;

  .preview {
    margin-left: 20px;
    height: 100px;

    p {
      font-size: 15px;
    }

  }
}

.adventure-type-game-starter-btn {
  margin-bottom: 0;
}

.sortable-item {
  //z-index: 10000;
  list-style: none;
  padding: 0;
}

.adventure-type .sortable-item {
  //position: relative;
  cursor: grab;

  & > * {
    cursor: initial;
  }

  //&:after {
  //  content: "\0133";
  //  position: absolute;
  //  //top: 0;
  //  left: 50%;
  //  font-size: 20px;
  //  border: 1px solid red;
  //  width: 100px;
  //  height: 40px;
  //  transform: translateX(-50%);
  //}
}