@import "./variables/variables-gutters.scss";
@import "./variables/variables-colors.scss";
@import "./variables/variables-font-size.scss";
@import "./variables/keyframes.scss";
@import "./variables/variables-viewports.scss";

.single-frame-container {
  padding-left: 20px;
  padding-right: 20px;

  main {
    height: 100%;
  }
}

.single-frame-box {
  background-color: #fff;
  box-shadow: 0 0 3px rgba(200, 200, 200, 0.3);
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 10px;
  min-height: 100%;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }
  .single-frame-box {
    box-shadow: none;
    height: auto;
    background-color: transparent;
    //padding: 0 10px;
     padding: 0;
  }
}

.single-frame-box-title {
  font-size: $font-size-sm;
  margin: 0;
  cursor: pointer;
  font-weight: bold;
}

.single-frame-box-content {
  //margin-top: 20px;
}

.single-frame-col {

  overflow: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  @media (min-width:992px) {
    height: calc(100vh - 120px);
  }
  &.fullScreen {
    //height: auto;
    order: -2;
  }
  .single-frame-main {
    //height: 100%;
    .single-frame-box {}
  }
  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }
}

.preview-tool-fullscreen {
  position: absolute;
  top: 0px;
  right: 0px;
  border: 0;
  padding: 0;
  cursor: pointer;
  outline: 0;
  background-color:transparent
}